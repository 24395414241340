import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import { NavLink, useNavigate } from "react-router-dom";
import serverPath from "../../utils/serverPath";
const DrawDates = (props) =>
{
  const navigate = useNavigate();
    const context = useContext(AuthContext);
    const [globalState, dispatch] = useStore();
    const {drawdates} = globalState;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() =>
    {
      if(!(context.role?.pages === "*"))
        return navigate("/", {replace: true});
      (async() => {
        if(drawdates.length <= 0)
        {
            try {
              
              if(isLoading)
                return;

              setIsLoading(true);
              const {data, status} = await (await fetch(serverPath("/draw_date"), {
                method: "get",
                headers: {
                  "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
                },
              })).json();
              if(status === "success" && data.length > 0)
              {
                dispatch('setData', {'type': "drawdates", data: data});
              }
                
            } catch (error) {
              console.log(error.message); 
            }
            setIsLoading(false);
        }
        if(drawdates.length > 0)
        {
          window.loadTable();
        }
      })()
    }, [])

    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Draw Dates</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-1">
                        <thead>
                          <tr>
                            <th className="text-center">
                              #
                            </th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? 
                            <tr>
                            <td colSpan={5} className="text-center">
                              <button className="btn btn-primary btn-progress" style={{height: 32}}></button>
                            </td>
                          </tr>
                           :
                          drawdates.length <= 0 ?                           
                          <tr >
                            <td colSpan={5} className="text-center">Nothing To Show</td>
                          </tr> : 
                          drawdates.map((perField, index) => {
                          return (
                          <tr key={perField.id}>
                            <td>
                              {(index + 1)}
                            </td>
                            <td>{perField.description}</td>
                            <td>{(new Date(perField.drawDate).toLocaleDateString())?.replace(/\//ig, "-")}</td>
                            <td>
                              {
                                perField.status ?
                                <div className="badge badge-success badge-shadow">True</div>
                                :
                                <div className="badge badge-warning badge-shadow">False</div>
                              }
                            </td>
                            <td><NavLink to={`/draw_date/edit/${perField.id}`} className="btn btn-primary">Edit / Delete</NavLink></td>
                          </tr>
                          )})}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>
      </div>
    )
}


export default DrawDates;