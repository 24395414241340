import React, { useContext, useEffect } from "react";
// import styles from './style.module.css';
import useStore from "../../store/store";
import { NavLink } from "react-router-dom";

const Currency = (props) =>
{
  const [globalState] = useStore();
  const {currencies} = globalState;


  return (
    <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Currencies</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover" id="tableExport" style={{width:"100%"}}>
                      <thead>
                        <tr>
                          <th>
                            #
                          </th>
                          <th>Main Name</th>
                          <th>Amount</th>
                          <th>Vector</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {currencies.map((currency, index) => (
                        <tr key={(currency.id + index)}>
                          <td>
                            {(index + 1)}
                          </td>
                          <td>{currency.mainName}</td>
                          <td>
                            {currency.amount}
                          </td>
                          <td>
                            {currency.vector ? "UP" : "DOWN"}
                          </td>
                          <td>
                            {
                              currency.status == true ?
                              <div className="badge badge-success badge-shadow">Active</div>
                              :
                              <div className="badge badge-warning badge-shadow">Suspended</div>
                            }
                          </td>
                          <td><NavLink to={`/currency/edit/${currency.id}`} className="btn btn-primary">Edit / Delete</NavLink></td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}


export default Currency;