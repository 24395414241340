import {initState} from './store';

const millionaireStore = () =>
{

  const setData = ({type, data}, globalState) => ({[type]: data});

  const initialState = (payload, globalState) => ({
    admins: [],
    products: [],
    news: [],
    gifts: [],
    tokens: [],
    sliders: [],
    statics: [],
    contacts: [],
    drawdates: [],
    roles: [],
    currencies: [],
    tokensSales: [],
    extras: {
      totalTokens: 0,
      totalSoldTokens: 0,
      totalUnsoldTokens: 0,
      totalUsers: 0,
    },
    traffics: {
      thisYear: null,
      thisMonth: null,
      today: null,
    },
    });
  
  return initState({
    initialState, 
    setData, 
  })
}


export default millionaireStore;