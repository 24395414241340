import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import { NavLink } from "react-router-dom";
const Sliders = (props) =>
{
    const context = useContext(AuthContext);
    const [globalState] = useStore();
    const {sliders} = globalState;

    useEffect(() =>
    {
      if(sliders.length > 0)
      {
        window.loadTable();
      }
      // window.loadForm();
    }, [sliders])

    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Your Sliders</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-1">
                        <thead>
                          <tr>
                            <th className="text-center">
                              #
                            </th>
                            <th>View Image</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sliders.map((slider, index) => {
                          return (
                          <tr key={slider.id}>
                            <td>
                              {(index + 1)}
                            </td>
                            <td><a href={serverPath(slider.imagePath)} target="_blank">Preview Image</a></td>
                            <td>{(new Date(slider.createdAt).toLocaleDateString())?.replace(/\//ig, "-")}</td>
                            <td><NavLink to={`/slider/edit/${slider.id}`} className="btn btn-primary">Edit / Delete</NavLink></td>
                          </tr>
                          )})}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>
      </div>
    )
}


export default Sliders;