import React from "react";
import Text from "../../Components/Text";
import styles from './style.module.css'

const FourOFour = (props) =>
{
    return (
        <div className={styles.conatiner}>

            <div className={styles.circle}>
                <p>
                    <span className={styles.one}>4</span>
                    <span className={styles.two} style={{fontFamily: "chiller"}}>O</span>
                    <span className={styles.three}>4</span>
                </p>
                <span className={styles.spinner}></span>
            </div>
            <Text style={{textAlign: "center", perspective: 800, fontFamily: "chiller", fontSize: 40}}>
                Page Not Found
            </Text>
        </div>
    )
}


export default FourOFour