import React, {useEffect} from 'react';
import AppWrapper from './Containers/AppWrapper';
import './style.css'
import AuthProvider from './authContext';
function App() {
  useEffect(() =>
  {
  }, [])
  return (
    <div className="main-wrapper main-wrapper-1">
      <AuthProvider>
        <AppWrapper />
      </AuthProvider>
    </div>
  );
}

export default App;
