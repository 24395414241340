import * as yup from 'yup';
export default (data) =>
{
  let schema = yup.object().shape({
    title: yup.string().optional().default(""),
    description: yup.string().optional().default(""),
    });

    try {
      schema.validateSync({...data});
      return {status: 'success', data: {...data}};
    } catch (error) {
      return {status: 'failure', message: error.errors[0]}
    }
}