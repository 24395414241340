import React, {memo} from "react";
import styles from './style.module.css'



const Text = (props) =>
{
  return (
    <p {...props} className={[styles.text, props.className].join(" ")} style={props.style}>
      {props.children}
    </p>
  )
}


export default memo(Text);