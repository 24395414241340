import axios from 'axios';
import serverPath from '../../utils/serverPath';
export default async (routerPath, id) =>
{
  try {
    const {data} = await axios.delete(serverPath(`/${routerPath}`), {
      data: {
        id
      },
      headers: {
        "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
      }
    });
    return data;
  } catch (error) {
    return {
      status: "failure",
      message: error.message
    }
  }
}