import React, {useContext, useEffect, useState} from "react";
import styles from './style.module.css';


const Plans = (props) =>
{
  return (
    <div className="main-content">
      <section className="section">
        <div className="row">
          <div className={[styles.text, 'm-auto'].join(" ")}>
            <h1>Coming Soon</h1>
            <p>
              We have plans to add several kinds of services to the website, 
              and currently, we are working on them. The menu you are currently viewing is still in progress. 
              Once we add the feature, you will be able to use the menu and its associated features.
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Plans;