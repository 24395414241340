import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate, useParams} from 'react-router-dom';
import AskAlert from '../../utils/AskAlert';
import deleteOne from '../../utils/deleteOne';
const UpdateSlider = (props) =>
{

  const {id} = useParams();

  const initSlider = {
    imagePath: null,
  };
  const navigate = useNavigate();
  const [globalState, dispatch] = useStore();
  const {sliders} = globalState;
  const [isLoading, setIsLoading] = useState(false);
  const [slider, setSlider] = useState({...initSlider});
  const [prevImage, setPrevImage] = useState(undefined);

  useEffect(() =>
  {

    window.loadFormImage();

    const slide = sliders.find(slide => slide.id == id)
    if(slide?.id)
    { 
      setSlider({
        imagePath: slide.imagePath,
      });
      setPrevImage(slide.imagePath)
    }

    if(sliders.length >= 1 && !(slide?.id))
      navigate("/sliders", {replace: true})        

  }, [sliders]);


  const changeHandler = (e, type) => {
    if(type !== "imagePath")
      setSlider(prev => ({...prev, [type]: e.target.value}));
    if(type === 'imagePath')
      if(e?.target?.files[0]?.name)
        setSlider(prev => ({...prev, [type]: e.target.files[0]}))
  };

  const submitHandler = async (e) =>
  {
    e.preventDefault();
    if(isLoading)
      return null;
    
    if(!(slider?.imagePath?.name))
      return SweetAlert("warning", "Slider Image Is Requred For Update !")
    const form = createValidForm({imagePath: !(slider?.imagePath?.name) ? prevImage : slider.imagePath, id: id});

    setIsLoading(true);
    const response = await fetch(serverPath("/slider"), {
      method: "PUT",
            headers: {
        "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
      },
      body: form
    }); 

    const objData = await response.json();
    
    setIsLoading(false)
    if(objData.status === "failure")
      return SweetAlert('error', objData.message);
      
      const updateSlider = objData.data;

      const newSlider = [...sliders];
      let index  = newSlider.findIndex(per => per.id == id)
      if(index >= 0)
      {
        newSlider[index] = {...newSlider[index], ...updateSlider};
        dispatch('setData', {type: 'sliders', data: newSlider});
      }
      return SweetAlert('success', "Slider Successfully added!").then(res => navigate('/sliders', {replace: true}));

  }

  const deleteHandler = async (e) =>
  {
    e.preventDefault();
    if(isLoading)
      return;
    let sure = await AskAlert({text: "Sure to delete this slider!"})
    if(!sure)
      return;
    setIsLoading(true);  
    const {status, message} = await deleteOne('slider', id);
    setIsLoading(false);
    if(status === 'success')
    {
      const newSlide = [...sliders];
      let index = newSlide.findIndex(per => per.id == id);
      if(index >= 0)
      {
        newSlide.splice(index, 1);
        dispatch('setData', {type: 'sliders', data: newSlide});
        SweetAlert('success', "Successfully Deleted");
      }
      navigate('/', {replace: true});
    }
    if(status === 'failure')
    {
      SweetAlert("error", message);
    }
  }

  

  return (
    <div className="main-content">
    <section className="section">
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Update Slider</h4>
              </div>
              <form onSubmit={submitHandler}>
                <div className="card-body">
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                    <div className="col-sm-12 col-md-7">
                      <div id="image-preview" className="image-preview">
                        <label htmlFor="image-upload" id="image-label">Choose File</label>
                        <input type="file" name="imagePath" id="image-upload" accept="image/*"  onChange={(e) => changeHandler(e, "imagePath")}/>
                      </div>
                    </div>
                  </div>
                  {prevImage &&
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Previous Image</label>
                    <div className="col-sm-12 col-md-7">
                      <a href={serverPath(prevImage)} target="_blank">Previous Image</a>
                    </div>
                  </div>
                  }
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                    <div className="col-sm-12 col-md-7">
                      <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Update Slider</button>
                      <button className={["btn btn-danger float-right", isLoading ? "btn-progress" : ""].join(" ")} onClick={deleteHandler} disabled={isLoading}>Delete Current Slider</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}


export default UpdateSlider;