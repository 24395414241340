import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import validator from "../../validators/admin";
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate, useParams} from 'react-router-dom';
import AskAlert from '../../utils/AskAlert';
import deleteOne from '../../utils/deleteOne';
const UpdateAdmin = (props) =>
{

  const {id} = useParams();

    const initAdmin = {
      firstName: "",
      lastName: "",
      phone: "",
      image: null,
      email: '',
      status: true,
      password: "",
      products: []
    };
    const navigate = useNavigate();
    const [globalState, dispatch] = useStore(false);
    const {admins} = globalState;
    const [isLoading, setIsLoading] = useState(false);
    const [isSaleLoading, setIsSaleLoading] = useState(false);
    const [admin, setAdmin] = useState({...initAdmin});
    const [prevImage, setPrevImage] = useState(undefined);

    useEffect(() =>
    {
      (async() => {
        try {

        const admn = admins.find(admn => admn.id == id)

        if(admins.length <= 0 || !(admn?.id))
          return navigate("/admins", {replace: true});

        if(admn?.id)
        {
          setAdmin({
            firstName: admn.firstName,
            lastName: admn.lastName,
            phone: admn.phone,
            image: admn.image,
            email: admn.email,
            products: admn?.products || [],
            status: admn.status,
            password: ""
          });
          setPrevImage(admn.image)
        }
        window.loadFormImage();
        window.loadForm();
          if((!admn.products) || (admn?.products?.length <= 0) )
          {
            setIsSaleLoading(true);
          const {data, status} = await (await fetch(serverPath("/sales"), {
            method: "POST",
            headers: {
              "Content-Type": "Application/JSON",
              "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
            },
            body: JSON.stringify({adminId: id})
          })).json();
      
          if(status === "success")
            {
              setAdmin(prev => ({...prev, products: data}))
              const newAdmin = [...admins];
              let index  = newAdmin.findIndex(per => per.id == id)
              if(index >= 0)
              {
                newAdmin[index] = {...newAdmin[index], products: data};
                dispatch('setData', {type: 'admins', data: newAdmin});
              }
            }
          }
      
        } catch (error) {
          console.log(error.message)
        }
        setIsSaleLoading(false);
      })()
    }, [admins]);




    const changeHandler = (e, type) => {
      if(type !== "image")
        setAdmin(prev => ({...prev, [type]: e.target.value}));
      if(type === 'image')
        if(e?.target?.files[0]?.name)
          setAdmin(prev => ({...prev, [type]: e.target.files[0]}))
    };
  
    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;


      let {status, data, message} = validator({...admin, password: (admin.password || "default")});
      if(status === 'failure')
        return SweetAlert("error", message);
      if(!admin.password)
        delete data.password
        
      const form = createValidForm({...data, image: !(admin?.image?.name) ? prevImage : admin.image, id: id});
  
      setIsLoading(true);
      const response = await fetch(serverPath("/admin"), {
        method: "PUT",
              headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: form
      }); 
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const updateAdmin = objData.data;

        const newAdmin = [...admins];
        let index  = newAdmin.findIndex(per => per.id == id)
        if(index >= 0)
        {
          newAdmin[index] = {...newAdmin[index], ...updateAdmin};
          dispatch('setData', {type: 'admins', data: newAdmin});
        }
        return SweetAlert('success', "Admin Successfully added!").then(res => navigate('/admins', {replace: true}));

    }
 
    const deleteHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;
        
      let sure = await AskAlert({text: "Sure to delete this admin!"})
      if(!sure)
        return;
      setIsLoading(true);  
      const {status, message} = await deleteOne('admin', id);
      setIsLoading(false);
      if(status === 'success')
      {
        const newAdmn = [...admins];
        let index = newAdmn.findIndex(per => per.id == id);
        if(index >= 0)
        {
          newAdmn.splice(index, 1);
          dispatch('setData', {type: 'admins', data: newAdmn});
          SweetAlert('success', "Successfully Deleted");
        }
        navigate('/admins', {replace: true});
      }
      if(status === 'failure')
      {
        SweetAlert("error", message);
      }
    }

    const editRoleHandler = (e) =>
    {
      e.preventDefault();
      navigate(`/role/edit/${id}`)
    }
  
    

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Admin Sales</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped" id="table-1">
                      <thead>
                        <tr>
                          <th className="text-center">
                            #
                          </th>
                          <th className="text-center">
                            Product
                          </th>
                          <th className="text-center">
                            Sold
                          </th>
                          <th className="text-center">
                            Unsold
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isSaleLoading ? 
                        <tr>
                          <td colSpan={4} className="text-center">Loading Please Wait...</td>
                        </tr>
                        : admin.products.length <= 0 ? 
                        <tr>
                          <td colSpan={4} className="text-center">Nothing To Show</td>
                        </tr>
                        :admin.products.map((per, index) => {
                        return (
                        <tr key={per.id}>
                          <td className="text-center">
                            {(index + 1)}
                          </td>
                          <td className="text-center">{per.name}</td>
                          <td className="text-center">{per.soldTokensCount}</td>
                          <td className="text-center">{per.unSoldTokensCount}</td>
                        </tr>
                        )})}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Update Admin</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">First Name</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" name="firstName" value={admin.firstName} onChange={(e) => changeHandler(e, "firstName")} required placeholder="FirstName"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Last Name</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" name="lastName" value={admin.lastName} onChange={(e) => changeHandler(e, "lastName")} required placeholder="LastName"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Phone</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" name="phone" value={admin.phone} onChange={(e) => changeHandler(e, "phone")} required placeholder="Mobile Number"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                      <div className="col-sm-12 col-md-7">
                        <div id="image-preview" className="image-preview">
                          <label htmlFor="image-upload" id="image-label">Choose File</label>
                          <input type="file" name="image" id="image-upload" accept="image/*"  onChange={(e) => changeHandler(e, "image")}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Email</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="email" className="form-control" name="email" value={admin.email} onChange={(e) => changeHandler(e, "email")}  placeholder="Email" required />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Password</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" name="password" value={admin.password} onChange={(e) => changeHandler(e, "password")}  placeholder="Password" />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Status</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" value={admin.status} onChange={(e) => changeHandler(e, "status")} name="status">
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>
                    {prevImage &&
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Previous Image</label>
                      <div className="col-sm-12 col-md-7">
                        <a href={serverPath(prevImage)} target="_blank">Previous Image</a>
                      </div>
                    </div>
                    }
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Update Admin</button>
                        <button className={["btn btn-danger float-right", isLoading ? "btn-progress" : ""].join(" ")} onClick={deleteHandler} disabled={isLoading}>Delete Current Admin</button>
                        <button className={["btn btn-info float-right mr-1", isLoading ? "btn-progress" : ""].join(" ")} onClick={editRoleHandler} disabled={isLoading}>Edit Role</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default UpdateAdmin;