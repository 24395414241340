import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import SweetAlert from "../../Components/SweetAlert";
import serverPath from "../../utils/serverPath";
import AskAlert from "../../utils/AskAlert";
import deleteOne from "../../utils/deleteOne";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const UpdateToken = (props) =>
{
    const {id} = useParams();
    const context = useContext(AuthContext);
    
    const navigate = useNavigate()
    const [globalState, dispatch] = useStore();
    const {tokens, admins, gifts} = globalState;
    const [isLoading, setIsLoading] = useState(false);

    
  let defaultValue = {
    token: 0,
    sold: false,
    rounde: 1,
    productId: 0,
    gifted: false,
    giftTaken: false,
    adminId: 0,
    giftId: 0,
  }

  const [fields, setFields] = useState({...defaultValue});
  const changeHandler = (e, type) => setFields(prev => ({...prev, [type]: e.target.value}));
  
  
    
    useEffect(() =>
    {

      window.loadForm();

      const tok = tokens.find(tok => tok.id == id)
      if(tok?.id)
      { 
        if(tok.isLucky)
          navigate("/tokens", {replace: true})
        setFields({
          id: tok.id,
          token: tok.token,
          sold: tok.sold,
          rounde: tok.rounde,
          gifted: tok.gifted,
          giftTaken: tok.giftTaken,
          productId: tok.productId,
          adminId: tok.adminId,
          giftId: tok.giftId,
        });
      }

      if(tokens.length >= 1 && !(tok?.id))
        navigate("/tokens", {replace: true})        

    }, [tokens]);


    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;
      try {
        let cloneFields = {...fields};
        let isCorrectGiftId = Number.parseInt(cloneFields.giftId);

          
        if(!cloneFields.gifted || ((typeof(cloneFields.gifted) !== "boolean") && (cloneFields?.gifted?.toLowerCase() == "false")) || !isCorrectGiftId)
        {
          cloneFields.giftTaken = false;
          cloneFields.gifted = false;
        }
        if(cloneFields.gifted && !isCorrectGiftId)
          return SweetAlert("info", "Please Select Gift First")
          
        if(!isCorrectGiftId)
        {
          delete cloneFields.giftId;
        }

        setIsLoading(true);
        const {data : objData} = await axios.put(
          serverPath("/token"), 
          {...cloneFields},
          {headers: {
            "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
          }}
        )
        setIsLoading(false);
        if(objData.status === "failure")
          return SweetAlert('error', objData.message);
        
          const updateToken = objData.data;
          const newTokens = [...tokens];
          let index  = newTokens.findIndex(per => per.id == id);
          console.log(updateToken)
          if(index >= 0)
          {
            newTokens[index] = { ...newTokens[index], ...updateToken};
            dispatch('setData', {type: 'tokens', data: newTokens});
          }
          return SweetAlert('success', "Product Successfully added!").then(res => navigate('/tokens', {replace: true}));
      } catch (error) {
        setIsLoading(false)
        return SweetAlert("error", error.message);
      }
    }

    
    const deleteHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;

        
      let sure = await AskAlert({text: "Sure to delete this token!"})
      if(!sure)
        return;
      setIsLoading(true);  
      const {status, message} = await deleteOne('token', id);
      setIsLoading(false);
      if(status === 'success')
      {
        const newTokens = [...tokens];
        let index = newTokens.findIndex(per => per.id == id);
        if(index >= 0)
        {
          newTokens.splice(index, 1);
          dispatch('setData', {type: 'tokens', data: newTokens});
          SweetAlert('success', "Successfully Deleted");
        }
        navigate('/tokens', {replace: true});
      }
      if(status === 'failure')
      {
        SweetAlert("error", message);
      }
    }

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Update Token</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Sold</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required onChange={(e) => changeHandler(e, "sold")} value={fields.sold}>
                          <option value={0}>Select</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>

                    { (context.role.pages === "*") && (
                      <>
                      <div className="form-group row mb-4">
                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Admin</label>
                        <div className="col-sm-12 col-md-7">
                          <select className="form-control" required onChange={(e) => changeHandler(e, "adminId")} value={fields.adminId}>
                            {admins.map((admin, index) => (
                              <option value={admin.id} key={admin.id}>{admin?.firstName + " " + admin?.lastName}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Select Gift</label>
                        <div className="col-sm-12 col-md-7">
                          <select className="form-control" required onChange={(e) => changeHandler(e, "giftId")} value={fields.giftId == null ? "null" : fields.giftId}>
                          <option value={"null"}>Select</option>
                            {gifts.map((perField, index) => (
                              <option value={perField.id} key={perField.id}>{perField?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      { !!Number.parseInt(fields.giftId) &&
                      <div className="form-group row mb-4">
                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Is Gifted</label>
                        <div className="col-sm-12 col-md-7">
                          <select className="form-control" required onChange={(e) => changeHandler(e, "gifted")} value={fields.gifted == null ? 0 : fields.gifted}>
                            <option value={0}>Select</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                          </select>
                        </div>
                      </div>}
                      </>
                    )
                    }
                    {(fields.gifted && !!Number.parseInt(fields.giftId)) &&
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Gift Taken</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required onChange={(e) => changeHandler(e, "giftTaken")} value={fields.giftTaken}>
                          <option value={0}>Select</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>
                    }
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={[`btn btn-primary ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading}>Update Token</button>
                        <button className={[`btn btn-danger float-right ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading} onClick={deleteHandler}>Delete This Token</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default UpdateToken;