import React, {useContext, useEffect, useState} from "react";
import styles from './style.module.css';
import { useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import { AuthContext } from "../../authContext";
import serverPath from "../../utils/serverPath";
const Traffics = (props) =>
{
  const {role} = useContext(AuthContext);
  const [globalState, dispatch] = useStore();
  const {traffics} = globalState;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() =>
  {
    (async() => {
      try {
        if(!(role?.pages === "*"))
        return navigate("/profile", {replace: true});

        if(traffics.thisYear === null)
        {
          if(isLoading)
            return
          setIsLoading(true)
          const {data, status} = await (await fetch(serverPath("/traffics"), {
            method: "POST",
            headers: {
              "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
            },
          })).json();
          if(status === "success")
            dispatch('setData', {'type': "traffics", data: data});
        }
      } catch (error) {
        console.log(error.message)   
      }
      setIsLoading(false);
      window.loadContent2();

      
  })()

  }, [traffics])
  return !(role?.pages === "*") ? null :(
    <div className="main-content">
      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Traffics</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th>This Year</th>
                        <th>This Month</th>
                        <th>Today</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                      {isLoading ? 
                        <td colSpan={3} className="text-center">
                          <button className="btn btn-primary btn-progress" style={{height: 32}}></button>
                        </td>
                        : (traffics.thisYear === null) ?
                        <td colSpan={3} className="text-center">No traffics to show</td>
                        :
                        <>
                        <td>{traffics.thisYear}</td>
                        <td>{traffics.thisMonth}</td>
                        <td>{traffics.today}</td>
                        </>
                      }
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}



export default Traffics;