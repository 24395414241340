import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import validator from "../../validators/gift";
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate} from 'react-router-dom';
import { AuthContext } from "../../authContext";
const CreateGift = (props) =>
{

    const initFields = {
      name: "",
      photo: null,
    };
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [globalState, dispatch] = useStore();
    const {gifts} = globalState;
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({...initFields});

    useEffect(() =>
    {
      window.loadFormImage();
      window.loadForm();
    }, []);


    const changeHandler = (e, type) => {
      if(type !== "photo")
        setFields(prev => ({...prev, [type]: e.target.value}));
  
      if(type === 'photo')
        if(e?.target?.files[0]?.name)
          setFields(prev => ({...prev, [type]: e.target.files[0]}))
    };
  




    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;

      const {status, data, message} = validator(fields);
      if(status === 'failure')
        return SweetAlert("error", message);
      if(!(fields?.photo?.name))
        return SweetAlert("error", "Gift Image Is Required!");
  
      const form = createValidForm({...data, photo: fields.photo});
  
      setIsLoading(true);
      const response = await fetch(serverPath("/gift"), {
        method: "POST",
        headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: form
      });
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const addedData = objData.data;
        addedData[0].tokenCount = 0;
        addedData[0].admin = user;
        const newData = [...gifts, ...addedData];
        dispatch('setData', {type: 'gifts', data: newData});
        
        return SweetAlert('success', "Gift Successfully added!").then(res => navigate('/gifts', {replace: true}));

    }

    

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Create Your Gift</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Name</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" value={fields.name} onChange={(e) => changeHandler(e, "name")} placeholder="Name"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                      <div className="col-sm-12 col-md-7">
                        <div id="image-preview" className="image-preview">
                          <label htmlFor="image-upload" id="image-label">Choose File</label>
                          <input type="file" id="image-upload" accept="image/*" required  onChange={(e) => changeHandler(e, "photo")}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Create Gift</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default CreateGift;