import React, { useEffect, useState } from 'react';
import serverPath from '../utils/serverPath';
import useStore from '../store/store';
import SweetAlert from '../Components/SweetAlert';
import checkRole from '../utils/checkRole';
const initialState = {
    login: false,
    token: null,
    user: null,
    role: null,
    loading: false,
    tokenPageNumber: 1
};

export const AuthContext = React.createContext({
    ...initialState,
    initialState: initialState,
    setAuth: (prev) => {},
});

let A_TOKEN = "A_TOKEN";



const AuthProvider = (props) =>
{

    const dispatch = useStore(false)[1];


    const [auth, setAuth] = useState({...initialState});
    useEffect(() =>
    {
        let authtoken = localStorage.getItem(A_TOKEN);
        if(!authtoken) return;
        (async() =>
        {
          setAuth(prev => ({...prev, loading: true}));
          try {
            const response = await fetch(serverPath("/auth/token"), {
            method: "POST",
            headers: {
              "Authorization": `bearer ${authtoken}`
            },
            });
            const objData = await response.json();
            if (objData.status === 'failure') {
                  localStorage.removeItem(A_TOKEN);
                  window.location.reload();
                return 
            };

            if(objData.status === 'success')
            {
              const {data, status} = await (await fetch(serverPath("/admin/bulk"), {
                method: "POST",
                headers: {
                  "Authorization": `bearer ${authtoken}`
                },
              })).json();
              if(status === "success")
                  data.forEach(perModelData => {
                      dispatch('setData', {...perModelData});
                  });
              setAuth((prev) => ({
                ...prev, 
                login: true, 
                loading: false, 
                token: authtoken,
                user: objData.user,
                role: checkRole(objData.user.role.permissions)
              }));
            }
          } catch (error) {
            localStorage.removeItem(A_TOKEN);
            window.location.reload();
          }
        })();

    }, [])

    return (
        <AuthContext.Provider value={{
            ...auth,
            initialState,
            setAuth
         }}>
            {props.children}
        </AuthContext.Provider>
    )
}


export default AuthProvider;