import React, { useContext, useEffect, useRef } from "react";
import useStore from "../../store/store";
import { useNavigate } from "react-router-dom";
import SweetAlert from "../../Components/SweetAlert";
import styles from './style.module.css';
import GOLD from '../../Assets/gold.jpg';
import SILVER from '../../Assets/silver.jpg';
import DIAMOND from '../../Assets/diamond.jpg';
import { AuthContext } from "../../authContext";

const PrintTokens = (props) =>
{
    const [globalState] = useStore();
    const context = useContext(AuthContext);
    const navigate = useNavigate();
    const {tokens} = globalState;

    const navBG = document.querySelector(".navbar-bg");
    const nav = document.querySelector(".navbar.main-navbar");
    const aside = document.querySelector(".main-sidebar");
    const btn = useRef();

    useEffect(() =>
    {
      (async() => {
        if(tokens.length <= 0)
        {
          await SweetAlert("info", "There Is No Token To Print")
          navigate("/tokens", {replace: true});
          return 
        }
      })()
    }, [tokens]);

    window.onbeforeprint = (e) =>
    {
      navBG.style.display = "none";
      nav.style.display = "none";
      aside.style.display = "none";
      btn.current.style.display = "none";
    }
    window.onafterprint = (e) =>
    {
      navBG.style.display = "block";
      nav.style.display = "flex";
      aside.style.display = "block";
      btn.current.style.display = "inline-block"
    }

    return (
      <div className="main-content">
        <section className="section">
          <button className="btn btn-primary" ref={btn} onClick={()=>window.print()}>Print</button>
          <div className={[styles.onPrint].join(' ')} style={{justifyContent: "space-between"}}>
            {tokens.map((token, index) => {
              const productName = (token.product.name).toLowerCase();
              return(
                // <div className="col-5 m-t-50" style={{position: "relative"}} key={token.id}>
                <div style={{position: "relative", marginTop: 60}} key={token.id}>
                  <img src={productName === 'gold' ? GOLD : productName === 'silver' ? SILVER : DIAMOND } className={styles.token_bg} />
                  <span className={[styles.tokenSpan, 'tokenSpan'].join(" ")}>{token.token}</span>
                  <span className={[styles.tokenNumber, 'tokenNumber'].join(" ")}>{context.tokenPageNumber + "-" + (index + 1)}</span>
                </div>
              )
            })}
          </div>
        </section>
      </div>
    )
}


export default PrintTokens;