import * as yup from 'yup';
export default (data) =>
{
  let schema = yup.object().shape({
    firstName: yup.string().max(30).required(),
    lastName: yup.string().max(20).required(),
    email: yup.string().max(60).email().required(),
    prevPassword: yup.string().required(),
    newPassword: yup.string().optional().min(6).max(60),
    });

    try {
      schema.validateSync({...data});
      return {status: 'success', data: {...data, image: data.image}};
    } catch (error) {
      return {status: 'failure', message: error.errors[0]}
    }
}