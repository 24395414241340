import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import { NavLink } from "react-router-dom";
const Products = (props) =>
{
    const context = useContext(AuthContext);
    const [globalState] = useStore();
    const {products} = globalState;

    useEffect(() =>
    {
      if(products.length > 0)
      {
        window.loadTable();
      }
      // window.loadForm();
    }, [products])

    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Your Products</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-1">
                        <thead>
                          <tr>
                            <th className="text-center">
                              #
                            </th>
                            <th>Name</th>
                            <th>Target</th>
                            <th>Progress</th>
                            <th>Image</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((product, index) => {
                            const target = (product.tokenCount / product.target) * 100;  
                          return (
                          <tr key={product.id}>
                            <td>
                              {(index + 1)}
                            </td>
                            <td>{product.name}</td>
                            <td>{product.target}</td>
                            <td className="align-middle">
                              <div className="progress progress-xs">
                                <div className={[`progress-bar bg-success width-per-${Math.floor(target < 100? target : 100)}`].join(' ')}>
                                </div>
                              </div>
                                {(target < 100 ? target.toFixed(2) : 100)}%
                            </td>
                            <td>
                              <a alt="image" href={serverPath(product.photo)} target="_blank">Preview</a>
                            </td>
                            <td>{(new Date(product.createdAt).toLocaleDateString())?.replace(/\//ig, "-")}</td>
                            <td>
                              {
                                product.status ?
                                <div className="badge badge-success badge-shadow">True</div>
                                :
                                <div className="badge badge-warning badge-shadow">False</div>
                              }
                            </td>
                            <td><NavLink to={`/product/edit/${product.id}`} className="btn btn-primary">Edit / Delete</NavLink></td>
                          </tr>
                          )})}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>
      </div>
    )
}


export default Products;