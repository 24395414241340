import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import SweetAlert from "../../Components/SweetAlert";
import serverPath from "../../utils/serverPath";
import AskAlert from "../../utils/AskAlert";
import deleteOne from "../../utils/deleteOne";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import dateToDatetimeLocal from '../../utils/dateToDatetimeLocal';

const UpdateDrawDate = (props) =>
{
    const {id} = useParams();
    
    const navigate = useNavigate()
    const [globalState, dispatch] = useStore();
    const {drawdates} = globalState;
    const [isLoading, setIsLoading] = useState(false);

    
  let defaultValue = {
    description: "",
    drawDate: new Date(),
    status: false,
  }

  const [fields, setFields] = useState({...defaultValue});
  const changeHandler = (e, type) => setFields(prev => ({...prev, [type]: e.target.value}));
  
  
    
    useEffect(() =>
    {

      window.loadForm();

      const findField = drawdates.find(findField => findField.id == id)
      if(drawdates.length <= 0 || !(findField?.id))
        navigate("/draw_date", {replace: true})

      if(findField?.id)
      { 
        setFields({
          description: findField.description,
          drawDate: dateToDatetimeLocal(findField.drawDate),
          status: findField.status,
        });
      }
    }, [drawdates]);


    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;
      try {
        let cloneFields = {...fields};

        setIsLoading(true);
        const {data : objData} = await axios.put(
          serverPath("/draw_date"), 
          {...cloneFields, id},
          {headers: {
            "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
          }}
        )
        setIsLoading(false);
        if(objData.status === "failure")
          return SweetAlert('info', objData.message);
        
          const updatedData = objData.data;
          const newData = [...drawdates];
          let index  = newData.findIndex(per => per.id == id);
          if(index >= 0)
          {
            newData[index] = { ...newData[index], ...updatedData};
            dispatch('setData', {type: 'drawdates', data: newData});
          }
          return SweetAlert('success', "Draw Date Successfully Updated!").then(res => navigate('/draw_date', {replace: true}));
      } catch (error) {
        setIsLoading(false)
        return SweetAlert("error", error.message);
      }
    }

    
    const deleteHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;

        
      let sure = await AskAlert({text: "Sure to delete this Date!"})
      if(!sure)
        return;
      setIsLoading(true);  
      const {status, message} = await deleteOne('draw_date', id);
      setIsLoading(false);
      if(status === 'success')
      {
        const newData = [...drawdates];
        let index = newData.findIndex(per => per.id == id);
        if(index >= 0)
        {
          newData.splice(index, 1);
          dispatch('setData', {type: 'drawdates', data: newData});
          SweetAlert('success', "Successfully Deleted");
        }
        navigate('/draw_date', {replace: true});
      }
      if(status === 'failure')
      {
        SweetAlert("error", message);
      }
    }

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Update Draw Date</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                  <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Description</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control"placeholder="Little Information" required value={fields.description} onChange={(e) => changeHandler(e, "description")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3" htmlFor="drawDate">Draw Date</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="datetime-local" id="drawDate" className="form-control" required value={fields.drawDate} onChange={(e) => changeHandler(e, "drawDate")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Status</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required onChange={(e) => changeHandler(e, "status")} value={fields.status}>
                          <option value={false}>Pending</option>
                          <option value={true}>Active</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={[`btn btn-primary ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading}>Update Date</button>
                        <button className={[`btn btn-danger float-right ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading} onClick={deleteHandler}>Delete This Date</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default UpdateDrawDate;