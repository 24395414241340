import React, {useContext, useState} from "react";
import serverPath from "../../utils/serverPath";
import SweetAlert from "../../Components/SweetAlert";
import { AuthContext } from "../../authContext";
import * as yup from 'yup';
import checkRole from "../../utils/checkRole";
import useStore from "../../store/store";
const Login = (props) =>
{
  
  const authContext = useContext(AuthContext);
  const [, dispatch] = useStore(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({
    email: "",
    password: ""
  })


  const submitHandler = async (e) =>
  {
    e.preventDefault();
    try {
      const schema = yup.object().shape({
        email: yup.string().email().required("Email Field Is Required"),
        password: yup.string().required("Password Field Is required")
      });
      try {
        schema.validateSync({...fields});
      } catch (er) {
        return SweetAlert('error', er.errors[(er.errors.length - 1)]);
      }
      setIsLoading(true);
      const response = await fetch(serverPath("/login"), {
        method: "POST",
        headers: {
          "Content-Type": "Application/json"
        },
        body: JSON.stringify(fields)
      });
      const objData = await response.json();

      if (objData.status === 'failure') {
        setIsLoading(false);
        return SweetAlert('error', objData.message);
      }
      if (objData.status === 'success') {

        const {data, status} = await (await fetch(serverPath("/admin/bulk"), {
          method: "POST",
          headers: {
            "Authorization": `bearer ${objData.token}`
          },
        })).json();
        if(status === "success")
            data.forEach(perModelData => {
                dispatch('setData', {...perModelData});
            });

        authContext.setAuth((prev) => ({
          ...prev, 
          login: true, 
          loading: false, 
          token: objData.token,
          user: objData.user,
          role: checkRole(objData.user.role.permissions)
        }));
      }
        
      localStorage.setItem("A_TOKEN", objData.token);


    } catch (error) {
      setIsLoading(false);
      SweetAlert("error", error.message);
      return
    }
  }

  return (
    <section className="section">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
            <div className="card card-primary">
              <div className="card-header">
                <h4>Login</h4>
              </div>
              <div className="card-body">
                <form className="needs-validation" noValidate="" onSubmit={submitHandler}>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input id="email" type="email" className="form-control" name="email" tabIndex="1" required autoFocus value={fields.email} onChange={({target}) => setFields(prev => ({...prev, email: target.value}))}/>
                    <div className="invalid-feedback">
                      Please fill in your email
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="d-block">
                      <label htmlFor="password" className="control-label">Password</label>
                    </div>
                    <input id="password" type="password" className="form-control" name="password" tabIndex="2" required value={fields.password} onChange={({target}) => setFields(prev => ({...prev, password: target.value}))}/>
                    <div className="invalid-feedback">
                      please fill in your password
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className={["btn btn-primary btn-lg btn-block", isLoading ? "btn-progress" : ""].join(" ")} tabIndex="4" disabled={isLoading}>
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}



export default Login;