const checkRole = (permissions) =>
{
  let initList = {
    show: false,
    get: false,
    post: false,
    update: false,
    delete: false,
  }
	try {

    const newPermission = {};

    if(typeof(permissions) === 'string')
      permissions = JSON.parse(permissions)
    if(typeof(permissions) === 'string')
      permissions = JSON.parse(permissions)
      
		if(!permissions)
			return {...initList}

		if(permissions.pages === "*")
			return {
        pages: "*"
      }

      permissions?.forEach(perPermission => {
        const key = Object.keys(perPermission)[0];
  
        if(key === "pages")
          return
        const newList = {...perPermission[key]}
        newPermission[key] = {...newList, show: (newList.post || newList.get)}
      });

      return newPermission;

      
	} catch (error) {
    console.log("ERROR in checking role", error)
    return {...initList}
	}
};

export default checkRole;
