import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import validator from "../../validators/news";
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate, useParams} from 'react-router-dom';
import { AuthContext } from "../../authContext";
import AskAlert from '../../utils/AskAlert';
import deleteOne from '../../utils/deleteOne';
const UpdateNews = (props) =>
{

  const {id} = useParams();

  const initFields = {
    title: "",
    description: "",
    photo: null,
  };

  const navigate = useNavigate();
  const [globalState, dispatch] = useStore();
  const {news} = globalState;
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState({...initFields});
  const [prevImage, setPrevImage] = useState(undefined);

  useEffect(() =>
  {

    window.loadFormImage();
    window.loadForm();

    const nws = news.find(nws => nws.id == id)
    if(nws?.id)
    { 
      setFields({
        title: nws.title,
        description: nws.description,
        photo: nws.photo,
      });
      setPrevImage(nws.photo)
    }

    if(news.length >= 1 && !(nws?.id))
      navigate("/news", {replace: true})        

  }, [news]);


  const changeHandler = (e, type) => {
    if(type !== "photo")
      setFields(prev => ({...prev, [type]: e.target.value}));
    if(type === 'photo')
      if(e?.target?.files[0]?.name)
        setFields(prev => ({...prev, [type]: e.target.files[0]}))
  };

  const submitHandler = async (e) =>
  {
    e.preventDefault();
    if(isLoading)
      return null;

    const {status, data, message} = validator(fields);
    if(status === 'failure')
      return SweetAlert("error", message);

    const form = createValidForm({...data, photo: !(fields?.photo?.name) ? prevImage : fields.photo, id: id});

    setIsLoading(true);
    const response = await fetch(serverPath("/news"), {
      method: "PUT",
            headers: {
        "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
      },
      body: form
    }); 

    const objData = await response.json();
    
    setIsLoading(false)
    if(objData.status === "failure")
      return SweetAlert('error', objData.message);
      
      const updateNews = objData.data;

      const newNews = [...news];
      let index  = newNews.findIndex(per => per.id == id)
      if(index >= 0)
      {
        newNews[index] = {...newNews[index], ...updateNews};
        dispatch('setData', {type: 'news', data: newNews});
      }
      return SweetAlert('success', "News Successfully added!").then(res => navigate('/news', {replace: true}));

  }

  const deleteHandler = async (e) =>
  {
    e.preventDefault();
    let sure = await AskAlert({text: "Sure to delete this news!"})
    if(!sure)
      return;
    setIsLoading(true);  
    const {status, message} = await deleteOne('news', id);
    setIsLoading(false);
    if(status === 'success')
    {
      const newNews = [...news];
      let index = newNews.findIndex(per => per.id == id);
      if(index >= 0)
      {
        newNews.splice(index, 1);
        dispatch('setData', {type: 'news', data: newNews});
        SweetAlert('success', "Successfully Deleted");
      }
      navigate('/', {replace: true});
    }
    if(status === 'failure')
    {
      SweetAlert("error", message);
    }
  }

  

  return (
    <div className="main-content">
    <section className="section">
      <div className="section-body">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Update News</h4>
              </div>
              <form onSubmit={submitHandler}>
                <div className="card-body">
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Title</label>
                    <div className="col-sm-12 col-md-7">
                      <input type="text" className="form-control" value={fields.title} onChange={(e) => changeHandler(e, "title")} placeholder="News Title"/>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Description</label>
                    <div className="col-sm-12 col-md-7">
                      <input type="text" className="form-control" value={fields.description} onChange={(e) => changeHandler(e, "description")} placeholder="News Description"/>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                    <div className="col-sm-12 col-md-7">
                      <div id="image-preview" className="image-preview">
                        <label htmlFor="image-upload" id="image-label">Choose File</label>
                        <input type="file" id="image-upload" accept="image/*"  onChange={(e) => changeHandler(e, "photo")}/>
                      </div>
                    </div>
                  </div>
                  {prevImage &&
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Previous Image</label>
                    <div className="col-sm-12 col-md-7">
                      <a href={serverPath(prevImage)} target="_blank">Previous Image</a>
                    </div>
                  </div>
                  }
                  <div className="form-group row mb-4">
                    <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                    <div className="col-sm-12 col-md-7">
                      <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Update News</button>
                      <button className={["btn btn-danger float-right", isLoading ? "btn-progress" : ""].join(" ")} onClick={deleteHandler} disabled={isLoading}>Delete Current News</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}


export default UpdateNews;