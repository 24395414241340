import React, { useContext, useEffect, useState } from "react";
import validator from '../../validators/user';
import { AuthContext } from "../../authContext";
import SweetAlert from "../../Components/SweetAlert";
import createValidForm from "../../utils/createValidForm";
import serverPath from "../../utils/serverPath";
const Profile = (props) =>
{
    const context = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const initUser = {...context?.user};
    delete initUser.role;
    const [user, setUser] = useState(context?.user || {});

    useEffect(() =>
    {
      window.loadFormImage()
    }, []);


    const changeHandler = (type, e) => {
      if(type !== "image")
        setUser(prev => ({...prev, [type]: e.target.value}));
      if(type === 'image')
        if(e?.target?.files[0]?.name)
          setUser(prev => ({...prev, [type]: e.target.files[0]}))
    };
    
    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;

      const {status, data, message} = validator(user);
      if(status === 'failure')
        return SweetAlert("error", message);

      const form = createValidForm({...data, image: !(user?.image?.name) ? context.user.image : user.image, id: context.user.id});
  
      setIsLoading(true);
      const response = await fetch(serverPath("/update_profile"), {
        method: "PUT",
              headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: form
      }); 
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const updateUser = objData.data;

        let newUser = {...context.user, ...updateUser};
        context.setAuth(prev => ({...prev, user: newUser}));
        return SweetAlert('success', "User Successfully Updated!");

    }

    return (
    <div className="main-wrapper main-wrapper-1">
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row mt-sm-4">
              <div className="col-12 col-md-12 col-lg-4">
                <div className="card author-box">
                  <div className="card-body">
                    <div className="author-box-center m-b-15">
                      <img alt="image" src={(context.user?.image ? serverPath(context.user?.image) : "assets/img/profile.jpg")} className="rounded-circle author-box-picture" />
                      <div className="clearfix"></div>
                      <div className="author-box-name">
                        <a href="#">{user?.firstName + " " + user?.lastName}</a>
                      </div>
                      <div className="author-box-job">{user?.role?.name?.toUpperCase()}</div>
                    </div>
                    <p className="clearfix">
                        <span className="float-left">
                            Phone
                        </span>
                        <span className="float-right text-muted">
                            {user?.phone}
                        </span>
                      </p>
                    <p className="clearfix">
                        <span className="float-left">
                            Email
                        </span>
                        <span className="float-right text-muted">
                            {user?.email}
                        </span>
                      </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-8">
                <div className="card">
                  <div className="padding-20">
                    <ul className="nav nav-tabs" id="myTab2" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="profile-tab2" data-toggle="tab" href="#settings" role="tab"
                          aria-selected="true">Setting</a>
                      </li>
                    </ul>
                    <div className="tab-content tab-bordered" id="myTab3Content">
                      <div className="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="profile-tab2">
                        <form method="post" className="needs-validation" onSubmit={submitHandler}>
                          <div className="card-header">
                            <h4>Edit Profile</h4>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="form-group col-md-6 col-12">
                                <label>New Photo</label>
                                <div className="col-sm-12 col-md-7">
                                  <div id="image-preview" className="image-preview">
                                    <label htmlFor="image-upload" id="image-label">Choose File</label>
                                    <input type="file" name="image" accept="image/*" id="image-upload" onChange={((e) => changeHandler("image", e))}/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6 col-12">
                                <label>First Name</label>
                                <input type="text" className="form-control" value={user?.firstName} onChange={((e) => changeHandler("firstName", e))} required/>
                                <div className="invalid-feedback">
                                  Please fill in the first name
                                </div>
                              </div>
                              <div className="form-group col-md-6 col-12">
                                <label>Last Name</label>
                                <input type="text" className="form-control" value={user?.lastName} onChange={((e) => changeHandler("lastName", e))} required/>
                                <div className="invalid-feedback">
                                  Please fill in the last name
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6 col-12">
                                <label>Email</label>
                                <input type="email" className="form-control" value={user?.email} onChange={((e) => changeHandler("email", e))} required/>
                                <div className="invalid-feedback">
                                  Please fill in the email
                                </div>
                              </div>
                              <div className="form-group col-md-6 col-12">
                                <label>Phone</label>
                                <input type="tel" className="form-control" value={user?.phone} onChange={((e) => changeHandler("phone", e))} required/>
                                <div className="invalid-feedback">
                                  Please fill in the phone
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6 col-12">
                                <label>Previous Password</label>
                                <input type="password" className="form-control" value={user?.prevPassword} onChange={((e) => changeHandler("prevPassword", e))} required/>
                                <div className="invalid-feedback">
                                  Please fill in the Previous Pass
                                </div>
                              </div>
                              <div className="form-group col-md-6 col-12">
                                <label>New Password</label>
                                <input type="text" className="form-control" value={user?.newPassword} onChange={((e) => changeHandler("newPassword", e))}/>
                                <div className="invalid-feedback">
                                  Please fill in the New Pass
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer text-right">
                            <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")}  disabled={isLoading}>Save Changes</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    )
}


export default Profile;