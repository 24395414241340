import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import SweetAlert from "../../Components/SweetAlert";
import serverPath from "../../utils/serverPath";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dateToDatetimeLocal from "../../utils/dateToDatetimeLocal";

const CreateDrawDate = (props) =>
{
    const context = useContext(AuthContext);
    
    const navigate = useNavigate()
    const [globalState, dispatch] = useStore();
    const {drawdates} = globalState;
    const [isLoading, setIsLoading] = useState(false);

    
  let defaultValue = {
    description: "",
    drawDate: new Date(),
    status: false,
  }

  const [fields, setFields] = useState({...defaultValue});
  const changeHandler = (e, type) => setFields(prev => ({...prev, [type]: e.target.value}));
    useEffect(() =>
    {
      window.loadForm();
    }, [])


    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;

      if((new Date(fields.drawDate)).getTime() <= (new Date()).getTime())
        return SweetAlert("info", "The Date You Entered Is Already Expired!");

      if(fields.description.length <= 0)
        return SweetAlert("info", "All Inputs Are Requred!");

      
      try {
    
        setIsLoading(true);
        const {data : objData} = await axios.post(
          serverPath("/draw_date"), 
          {...fields},
          {headers: {
            "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
          }}
        )
          
        setIsLoading(false);
        if(objData.status === "failure")
          return SweetAlert('error', objData.message);
        
        const addedDrawDate = objData.data;
        dispatch('setData', {type: 'drawdates', data: [addedDrawDate, ...drawdates]});
        
        return SweetAlert('success', " Date Successfully added!").then(res => navigate('/draw_date', {replace: true}));
  
  
      } catch (error) {
        setIsLoading(false);

        return SweetAlert("error", error.message);
      }
    }

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Create Draw Date</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Description</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control"placeholder="Little Information" required value={fields.description} onChange={(e) => changeHandler(e, "description")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3" htmlFor="drawDate">Draw Date</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="datetime-local" id="drawDate" className="form-control" required value={dateToDatetimeLocal(fields.drawDate)} onChange={(e) => changeHandler(e, "drawDate")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Status</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required onChange={(e) => changeHandler(e, "status")} value={fields.status}>
                          <option value={false}>Pending</option>
                          <option value={true}>Active</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={[`btn btn-primary ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading}>Create Date</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default CreateDrawDate;