import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import { NavLink } from "react-router-dom";
import serverPath from "../../utils/serverPath";
const Navbar = (props) =>
{
  const {user, role} = useContext(AuthContext);
  useEffect(() =>
  {
    window.loadContent();
  }, [])
  const logoutHandler = (e) =>
  {
    e.preventDefault();
    localStorage.removeItem("A_TOKEN");
    window.location.assign("/")
  }
  return (
    <>
    <div className="navbar-bg"></div>
      <nav className="navbar navbar-expand-lg main-navbar sticky">
        <div className="form-inline mr-auto">
          <ul className="navbar-nav mr-3">
            <li><NavLink to="#" data-toggle="sidebar" className="nav-link nav-link-lg
									collapse-btn"> <i data-feather="align-justify"></i></NavLink></li>
            <li><NavLink to="#" className="nav-link nav-link-lg fullscreen-btn">
                <i data-feather="maximize"></i>
              </NavLink></li>
            <li>
              <form className="form-inline mr-auto">
                <div className="search-element">
                  <input className="form-control" type="search" placeholder="Search" aria-label="Search" data-width="200" />
                  <button className="btn" type="submit">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </form>
            </li>
          </ul>
        </div>
        <ul className="navbar-nav navbar-right">
          <li className="dropdown"><NavLink to="#" data-toggle="dropdown"
              className="nav-link dropdown-toggle nav-link-lg nav-link-user"> <img alt="image" src={(user?.image ? serverPath(user?.image) : "assets/img/profile.jpg")}
                className="user-img-radious-style" /> <span className="d-sm-none d-lg-inline-block"></span></NavLink>
            <div className="dropdown-menu dropdown-menu-right pullDown">
              <div className="dropdown-title">Hello {user?.lastName}</div>
              <NavLink to="/profile" className="dropdown-item has-icon"> <i className="far
										fa-user"></i> Profile
              </NavLink>
              <div className="dropdown-divider"></div>
              <NavLink to="/logout" className="dropdown-item has-icon text-danger" onClick={logoutHandler}> <i className="fas fa-sign-out-alt"></i>
                Logout
              </NavLink>
            </div>
          </li>
        </ul>
      </nav>
      <div className="main-sidebar sidebar-style-2">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <NavLink to="/"> <img alt="image" src="assets/img/logo.png" className="header-logo" /> <span
                className="logo-name">Millonar</span>
            </NavLink>
          </div>
          <ul className="sidebar-menu">
            <li className="menu-header">Main</li>

            { (role?.pages === "*") ?
            <>
              <li className="dropdown">
                <NavLink to="/" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="monitor"></i><span>Dashboard</span></NavLink>
              </li>
              <li className="dropdown">
                <NavLink to="/profile" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="user"></i><span>Profile</span></NavLink>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="users"></i><span>Admins</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_admin">Create Admin</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/admins">Admins</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="airplay"></i><span>Slider</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_slider">Create Slider</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/sliders">Sliders</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="package"></i><span>Product</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_product">Create Product</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/products">Products</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="hash"></i><span>Token</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_token">Create Token</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/tokens">Tokens</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_lucky">Create Lucky Token</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="book-open"></i><span>News</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_news">Create News</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/news">News</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="inbox"></i><span>Draw</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_draw_date">Create Draw Date</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/draw_date">Draw Date</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/show_draw">Show Lucky</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="gift"></i><span>Gift</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_gift">Create Gift</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/gifts">Gifts</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="dollar-sign"></i><span>Currencies</span></a>
                <ul className="dropdown-menu">
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_currency">Create Currency</NavLink></li>
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/currencies">Currencies</NavLink></li>
                </ul>
              </li>
              <li className="dropdown">
                <NavLink to="/traffics" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="activity"></i><span>Traffics</span></NavLink>
              </li>
            </>
            :
            <>
              <li className="dropdown">
                <NavLink to="/" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="monitor"></i><span>Dashboard</span></NavLink>
              </li>
              <li className="dropdown">
                <NavLink to="/profile" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="user"></i><span>Profile</span></NavLink>
              </li>
              {role?.admin?.show && 
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="users"></i><span>Admins</span></a>
                <ul className="dropdown-menu">
                  {role?.admin?.post && 
                    <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_admin">Create Admin</NavLink></li>
                  }
                  {role?.admin?.get && 
                    <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/admins">Admins</NavLink></li>
                  }
                </ul>
              </li>
              }
              {role?.slider?.show && 
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="airplay"></i><span>Slider</span></a>
                <ul className="dropdown-menu">
                  {role?.slider?.post && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_slider">Create Slider</NavLink></li>
                  }
                  {role?.slider?.get && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/sliders">Sliders</NavLink></li>
                  }
                </ul>
              </li>
              }
              {
              role?.product?.show && 
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="package"></i><span>Product</span></a>
                <ul className="dropdown-menu">
                  {role?.product?.post && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_product">Create Product</NavLink></li>
                  }
                  {role?.product?.get && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/products">Products</NavLink></li>
                  }
                </ul>
              </li>
              }
              {role?.token?.show && 
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="gift"></i><span>Token</span></a>
                <ul className="dropdown-menu">
                  {role?.token?.post && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_token">Create Token</NavLink></li>
                  }
                  {role?.token?.get && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/tokens">Tokens</NavLink></li>
                  }
                  {role?.lucky?.show && 
                    <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_lucky">Create Lucky Token</NavLink></li>
                  }
                </ul>
              </li>
              }
              {role?.news?.show && 
              <li className="dropdown">
                <a href="#" className="menu-toggle nav-link has-dropdown"><i data-feather="book-open"></i><span>News</span></a>
                <ul className="dropdown-menu">
                  {role?.news?.post && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/create_news">Create News</NavLink></li>
                  }
                  {role?.news?.get && 
                  <li><NavLink className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} to="/news">News</NavLink></li>
                  }
                </ul>
              </li>
              }

            </>
            }
            <li className="dropdown">
              <NavLink to="/plans" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="send"></i><span>Kam Air</span></NavLink>
            </li>
            <li className="dropdown">
              <NavLink to="/plans" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="send"></i><span>Ariana Afghan</span></NavLink>
            </li>
            <li className="dropdown">
              <NavLink to="/plans" className={({isActive}) => ["nav-link", isActive ? "navActive bg-light" : ""].join(' ')} ><i data-feather="send"></i><span>Electricity bills</span></NavLink>
            </li>
          </ul>
        </aside>
        <div className="settingSidebar">
        <NavLink to="#" className="settingPanelToggle"> <i className="fa fa-spin fa-cog"></i>
        </NavLink>
        <div className="settingSidebar-body ps-container ps-theme-default">
          <div className=" fade show active">
            <div className="setting-panel-header">Setting Panel
            </div>
            <div className="p-15 border-bottom">
              <h6 className="font-medium m-b-10">Select Layout</h6>
              <div className="selectgroup layout-color w-50">
                <label className="selectgroup-item" htmlFor="lightOne">
                  <input type="radio" name="value" value="1" id="lightOne" className="selectgroup-input-radio select-layout" checked  onChange={(e) => e.target.checked = true}/>
                  <span className="selectgroup-button">Light</span>
                </label>
                <label className="selectgroup-item" htmlFor="darkOne">
                  <input type="radio" name="value" value="2" id="darkOne" className="selectgroup-input-radio select-layout" onChange={(e) => e.target.checked = true}/>
                  <span className="selectgroup-button">Dark</span>
                </label>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <h6 className="font-medium m-b-10">Sidebar Color</h6>
              <div className="selectgroup selectgroup-pills sidebar-color">
                <label className="selectgroup-item">
                  <input type="radio" name="icon-input" value="1" className="selectgroup-input select-sidebar" />
                  <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip"
                    data-original-title="Light Sidebar"><i className="fas fa-sun"></i></span>
                </label>
                <label className="selectgroup-item">
                  <input type="radio" name="icon-input" value="2" className="selectgroup-input select-sidebar" checked onChange={(e) => e.target.checked = true}/>
                  <span className="selectgroup-button selectgroup-button-icon" data-toggle="tooltip"
                    data-original-title="Dark Sidebar"><i className="fas fa-moon"></i></span>
                </label>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <h6 className="font-medium m-b-10">Color Theme</h6>
              <div className="theme-setting-options">
                <ul className="choose-theme list-unstyled mb-0">
                  <li title="white" className="active">
                    <div className="white"></div>
                  </li>
                  <li title="cyan">
                    <div className="cyan"></div>
                  </li>
                  <li title="black">
                    <div className="black"></div>
                  </li>
                  <li title="purple">
                    <div className="purple"></div>
                  </li>
                  <li title="orange">
                    <div className="orange"></div>
                  </li>
                  <li title="green">
                    <div className="green"></div>
                  </li>
                  <li title="red">
                    <div className="red"></div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <div className="theme-setting-options">
                <label className="m-b-0">
                  <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input"
                    id="mini_sidebar_setting" />
                  <span className="custom-switch-indicator"></span>
                  <span className="control-label p-l-10">Mini Sidebar</span>
                </label>
              </div>
            </div>
            <div className="p-15 border-bottom">
              <div className="theme-setting-options">
                <label className="m-b-0">
                  <input type="checkbox" name="custom-switch-checkbox" className="custom-switch-input"
                    id="sticky_header_setting" />
                  <span className="custom-switch-indicator"></span>
                  <span className="control-label p-l-10">Sticky Header</span>
                </label>
              </div>
            </div>
            <div className="mt-4 mb-4 p-3 align-center rt-sidebar-last-ele">
              <NavLink to="#" className="btn btn-icon icon-left btn-primary btn-restore-theme">
                <i className="fas fa-undo"></i> Restore Default
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}



export default Navbar