import React, { PureComponent, Suspense, lazy } from 'react';
import {Route, Routes} from 'react-router-dom';
import styles from './style.css';
import Navbar from '../Navbar';
import { AuthContext } from '../../authContext';
import Home from '../Home';
import PageNotFound from '../404';
import Profile from '../Profile';
import Traffics from '../Traffics';
import CreateCurrency from '../CreateCurrency';
import CreateProduct from '../CreateProduct';
import UpdateProduct from '../UpdateProduct';
import UpdateToken from '../UpdateToken';
import Products from '../Products';
import CreateToken from '../CreateToken';
import Tokens from '../Tokens';
import LuckyTokens from '../LuckyTokens';
import CreateSlider from '../CreateSliders';
import Sliders from '../Sliders';
import UpdateSlider from '../UpdateSlider';
import News from '../News';
import CreateNews from '../CreateNews';
import UpdateNews from '../UpdateNews';
import CreateAdmin from '../CreateAdmin';
import Admins from '../Admins';
import UpdateAdmin from '../UpdateAdmin';
import PrintTokens from '../PrintTokens';
import UpdateRole from '../UpdateRole';
import Currency from '../Currency';
import UpdateCurrency from '../UpdateCurrency';
import CreateGift from '../CreateGift';
import Gifts from '../Gifts';
import UpdateGift from '../UpdateGift';
import Plans from '../Plans';
import ShowDraw from '../ShowDraw';
import CreateDrawDate from '../CreateDrawDate';
import DrawDates from '../DrawDates';
import UpdateDrawDate from '../UpdateDrawDate';



class Layout extends PureComponent
{

  static contextType = AuthContext;
  constructor(props)
  {
    super(props)
    this.state = {
      role: {}
    }
  }
  componentDidMount()
  {
    this.setState({role: this.context.role})
  }

  render()
  {
    const role = this.state.role;
    const screens = role?.pages === '*' ? [
        {allow: true, screen: '/', Component: Home},
        {allow: true, screen: '/profile', Component: Profile},
        {allow: true, screen: '/traffics', Component: Traffics},

        {allow: true, screen: '/show_draw', Component: ShowDraw},
        {allow: true, screen: '/draw_date', Component: DrawDates},
        {allow: true, screen: '/create_draw_date', Component: CreateDrawDate},
        {allow: true, screen: '/draw_date/edit/:id', Component: UpdateDrawDate},

        {allow: true, screen: '/create_currency', Component: CreateCurrency},
        {allow: true, screen: '/currencies', Component: Currency},
        {allow: true, screen: '/currency/edit/:id', Component: UpdateCurrency},

        {allow: true, screen: '/products', Component: Products},
        {allow: true, screen: '/create_product', Component: CreateProduct},
        {allow: true, screen: '/product/edit/:id', Component: UpdateProduct},

        {allow: true, screen: '/tokens', Component: Tokens},
        {allow: true, screen: '/create_token', Component: CreateToken},
        {allow: true, screen: '/token/edit/:id', Component: UpdateToken},
        {allow: true, screen: '/create_lucky', Component: LuckyTokens},
        {allow: true, screen: '/tokens/print', Component: PrintTokens},
        
        {allow: true, screen: '/sliders', Component: Sliders},
        {allow: true, screen: '/create_slider', Component: CreateSlider},
        {allow: true, screen: '/slider/edit/:id', Component: UpdateSlider},
        
        {allow: true, screen: '/news', Component: News},
        {allow: true, screen: '/create_news', Component: CreateNews},
        {allow: true, screen: '/news/edit/:id', Component: UpdateNews},
        
        {allow: true, screen: '/gifts', Component: Gifts},
        {allow: true, screen: '/create_gift', Component: CreateGift},
        {allow: true, screen: '/gift/edit/:id', Component: UpdateGift},

        {allow: true, screen: '/admins', Component: Admins},
        {allow: true, screen: '/create_admin', Component: CreateAdmin},
        {allow: true, screen: '/admin/edit/:id', Component: UpdateAdmin},
        {allow: true, screen: '/role/edit/:id', Component: UpdateRole},
      ]
      :
      [
        {allow: true, screen: '/', Component: Home},
        {allow: true, screen: '/profile', Component: Profile},

        {allow: role?.product?.get, screen: '/products', Component: Products},
        {allow: role?.product?.post, screen: '/create_product', Component: CreateProduct},
        {allow: role?.product?.update, screen: '/product/edit/:id', Component: UpdateProduct},

        {allow: role?.token?.get, screen: '/tokens', Component: Tokens},
        {allow: role?.token?.post, screen: '/create_token', Component: CreateToken},
        {allow: role?.token?.update, screen: '/token/edit/:id', Component: UpdateToken},
        {allow: role?.lucky?.show, screen: '/create_lucky', Component: LuckyTokens},
        {allow: true, screen: '/tokens/print', Component: PrintTokens},
        
        {allow: role?.slider?.get, screen: '/sliders', Component: Sliders},
        {allow: role?.slider?.post, screen: '/create_slider', Component: CreateSlider},
        {allow: role?.slider?.update, screen: '/slider/edit/:id', Component: UpdateSlider},
        
        {allow: role?.news?.get, screen: '/news', Component: News},
        {allow: role?.news?.post, screen: '/create_news', Component: CreateNews},
        {allow: role?.news?.update, screen: '/news/edit/:id', Component: UpdateNews},


        {allow: role?.admin?.get, screen: '/admins', Component: Admins},
        {allow: role?.admin?.post, screen: '/create_admin', Component: CreateAdmin},
        {allow: role?.admin?.update, screen: '/admin/edit/:id', Component: UpdateAdmin},
        {allow: false, screen: '/role/edit/:id', Component: UpdateRole},
      ]
    return (
      <>
        <Navbar />
        <Routes>
          {
            screens.map((per, index) => {
                if(!per?.allow)
                  return 
            return (
              <Route path={`${per.screen}`} key={index} element={<per.Component />} />
            )})
          }
          <Route path="/plans"  element={<Plans />} />
          <Route path="*"  element={<PageNotFound />} />
        </Routes>
      </>

    )
  }
}


export default Layout;