import * as yup from 'yup';
export default (data) =>
{
  let schema = yup.object().shape({
    name: yup.string().required(),
    target: yup.string().required(),
    status: yup.boolean().required(),
    });

    try {
      schema.validateSync({...data});
      return {status: 'success', data: {...data}};
    } catch (error) {
      return {status: 'failure', message: error.errors[0]}
    }
}