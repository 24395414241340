import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import { NavLink } from "react-router-dom";
const News = (props) =>
{
    const context = useContext(AuthContext);
    const [globalState] = useStore();
    const {news} = globalState;

    useEffect(() =>
    {
      if(news.length > 0)
      {
        window.loadTable();
      }
      // window.loadForm();
    }, [news])

    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
              <div className="card">
                  <div className="card-header">
                    <h4>Your News</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped" id="table-1">
                        <thead>
                          <tr>
                            <th className="text-center">
                              #
                            </th>
                            <th>title</th>
                            <th>Image</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {news.map((nws, index) => {
                            const target = (nws.tokenCount / nws.target) * 100;  
                          return (
                          <tr key={nws.id}>
                            <td>
                              {(index + 1)}
                            </td>
                            <td>{nws.title}</td>
                            <td>
                              <a alt="image" href={serverPath(nws.photo)} target="_blank">Preview</a>
                            </td>
                            <td>{(new Date(nws.createdAt).toLocaleDateString())?.replace(/\//ig, "-")}</td>
                            <td><NavLink to={`/news/edit/${nws.id}`} className="btn btn-primary">Edit / Delete</NavLink></td>
                          </tr>
                          )})}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>
      </div>
    )
}


export default News;