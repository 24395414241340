import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import validator from "../../validators/admin";
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate} from 'react-router-dom';
import { AuthContext } from "../../authContext";
const CreateAdmin = (props) =>
{

    const initAdmin = {
      firstName: "",
      lastName: "",
      phone: "",
      image: null,
      email: '',
      password: '',
      status: true,
    };
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [globalState, dispatch] = useStore();
    const {admins} = globalState;
    const [isLoading, setIsLoading] = useState(false);
    const [admin, setAdmin] = useState({...initAdmin});

    useEffect(() =>
    {
      window.loadFormImage();
      window.loadForm();
    }, []);


    const changeHandler = (e, type) => {
      if(type !== "image")
        setAdmin(prev => ({...prev, [type]: e.target.value}));
  
      if(type === 'image')
        if(e?.target?.files[0]?.name)
          setAdmin(prev => ({...prev, [type]: e.target.files[0]}))
    };
  




    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;

      const {status, data, message} = validator(admin);
      if(status === 'failure')
        return SweetAlert("error", message);
      if(!(admin?.image?.name))
        return SweetAlert("error", "Admin Image Is Required!");
  
      const form = createValidForm({...data, image: admin.image});
  
      setIsLoading(true);
      const response = await fetch(serverPath("/admin"), {
        method: "POST",
        headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: form
      });
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const addedAdmin = objData.data;
        const newAdmins = [...admins, ...addedAdmin];
        dispatch('setData', {type: 'admins', data: newAdmins});
        
        return SweetAlert('success', "Admin Successfully added!").then(res => navigate('/admins', {replace: true}));

    }

    

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Create Admin</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">First Name</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" value={admin.firstName} onChange={(e) => changeHandler(e, "firstName")} required placeholder="F/Name"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Last Name</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" value={admin.lastName} onChange={(e) => changeHandler(e, "lastName")} required placeholder="L/Name"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                      <div className="col-sm-12 col-md-7">
                        <div id="image-preview" className="image-preview">
                          <label htmlFor="image-upload" id="image-label">Choose File</label>
                          <input type="file" name="image" id="image-upload" accept="image/*" required  onChange={(e) => changeHandler(e, "image")}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Phone</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="tel" className="form-control" value={admin.phone} onChange={(e) => changeHandler(e, "phone")}  placeholder="Phone Number" required />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Email</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" value={admin.email} onChange={(e) => changeHandler(e, "email")} required placeholder="Email"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Password</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" value={admin.password} onChange={(e) => changeHandler(e, "password")} required placeholder="Password"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Status</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" value={admin.status} onChange={(e) => changeHandler(e, "status")} name="status">
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Create Admin</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default CreateAdmin;