import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import validator from "../../validators/product";
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate} from 'react-router-dom';
import { AuthContext } from "../../authContext";
const CreateProduct = (props) =>
{

    const initProduct = {
      name: "",
      photo: null,
      target: 0,
      status: true,
    };
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    const [globalState, dispatch] = useStore();
    const {products} = globalState;
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({...initProduct});

    useEffect(() =>
    {
      window.loadFormImage();
      window.loadForm();
    }, []);


    const changeHandler = (e, type) => {
      if(type !== "photo")
        setProduct(prev => ({...prev, [type]: e.target.value}));
  
      if(type === 'photo')
        if(e?.target?.files[0]?.name)
          setProduct(prev => ({...prev, [type]: e.target.files[0]}))
    };
  




    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;

      const {status, data, message} = validator(product);
      if(status === 'failure')
        return SweetAlert("error", message);
      if(!(product?.photo?.name))
        return SweetAlert("error", "Product Image Is Required!");
  
      const form = createValidForm({...data, photo: product.photo});
  
      setIsLoading(true);
      const response = await fetch(serverPath("/product"), {
        method: "POST",
        headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: form
      });
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const addedProd = objData.data;
        addedProd[0].tokenCount = 0;
        addedProd[0].admin = user;
        const newProds = [...products, ...addedProd];
        dispatch('setData', {type: 'products', data: newProds});
        
        return SweetAlert('success', "Product Successfully added!").then(res => navigate('/products', {replace: true}));

    }

    

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Create Your Product</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Name</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" name="name" value={product.name} onChange={(e) => changeHandler(e, "name")} required placeholder="Product Name"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                      <div className="col-sm-12 col-md-7">
                        <div id="image-preview" className="image-preview">
                          <label htmlFor="image-upload" id="image-label">Choose File</label>
                          <input type="file" name="photo" id="image-upload" accept="image/*" required  onChange={(e) => changeHandler(e, "photo")}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Target</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="number" className="form-control" name="target" value={product.target} onChange={(e) => changeHandler(e, "target")}  placeholder="Must Be Numeric Value" required />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Status</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" value={product.status} onChange={(e) => changeHandler(e, "status")} name="status">
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Create Post</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default CreateProduct;