import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import validator from "../../validators/product";
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate, useParams} from 'react-router-dom';
import { AuthContext } from "../../authContext";
import AskAlert from '../../utils/AskAlert';
import deleteOne from '../../utils/deleteOne';
const UpdateProduct = (props) =>
{

  const {id} = useParams();

    const initProduct = {
      name: "",
      photo: null,
      target: 0,
      status: true,
    };
    const navigate = useNavigate();
    const [globalState, dispatch] = useStore();
    const {products} = globalState;
    const [isLoading, setIsLoading] = useState(false);
    const [product, setProduct] = useState({...initProduct});
    const [prevImage, setPrevImage] = useState(undefined);

    useEffect(() =>
    {

      window.loadFormImage();
      window.loadForm();

      const prod = products.find(prod => prod.id == id)
      if(prod?.id)
      { 
        setProduct({
          name: prod.name,
          photo: prod.photo,
          target: prod.target,
          status: prod.status,
        });
        setPrevImage(prod.photo)
      }

      if(products.length >= 1 && !(prod?.id))
        navigate("/products", {replace: true})        

    }, [products]);


    const changeHandler = (e, type) => {
      if(type !== "photo")
        setProduct(prev => ({...prev, [type]: e.target.value}));
      if(type === 'photo')
        if(e?.target?.files[0]?.name)
          setProduct(prev => ({...prev, [type]: e.target.files[0]}))
    };
  
    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;

      const {status, data, message} = validator(product);
      if(status === 'failure')
        return SweetAlert("error", message);

      const form = createValidForm({...data, photo: !(product?.photo?.name) ? prevImage : product.photo, id: id});
  
      setIsLoading(true);
      const response = await fetch(serverPath("/product"), {
        method: "PUT",
              headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: form
      }); 
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const updateProduct = objData.data;

        const newProduct = [...products];
        let index  = newProduct.findIndex(per => per.id == id)
        if(index >= 0)
        {
          newProduct[index] = {...newProduct[index], ...updateProduct};
          dispatch('setData', {type: 'products', data: newProduct});
        }
        return SweetAlert('success', "Product Successfully updated!").then(res => navigate('/products', {replace: true}));

    }
 
    const deleteHandler = async (e) =>
    {
      e.preventDefault();
      let sure = await AskAlert({text: "Sure to delete this product!"})
      if(!sure)
        return;
      setIsLoading(true);  
      const {status, message} = await deleteOne('product', id);
      setIsLoading(false);
      if(status === 'success')
      {
        const newProd = [...products];
        let index = newProd.findIndex(per => per.id == id);
        if(index >= 0)
        {
          newProd.splice(index, 1);
          dispatch('setData', {type: 'products', data: newProd});
          SweetAlert('success', "Successfully Deleted");
        }
        navigate('/', {replace: true});
      }
      if(status === 'failure')
      {
        SweetAlert("error", message);
      }
    }
  
    

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Update Product</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Name</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control" name="name" value={product.name} onChange={(e) => changeHandler(e, "name")} required placeholder="Product Name"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                      <div className="col-sm-12 col-md-7">
                        <div id="image-preview" className="image-preview">
                          <label htmlFor="image-upload" id="image-label">Choose File</label>
                          <input type="file" name="photo" id="image-upload" accept="image/*"  onChange={(e) => changeHandler(e, "photo")}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Target</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="number" className="form-control" name="target" value={product.target} onChange={(e) => changeHandler(e, "target")}  placeholder="Must Be Numeric Value" required />
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Status</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" value={product.status} onChange={(e) => changeHandler(e, "status")} name="status">
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </select>
                      </div>
                    </div>
                    {prevImage &&
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Previous Image</label>
                      <div className="col-sm-12 col-md-7">
                        <a href={serverPath(prevImage)} target="_blank">Previous Image</a>
                      </div>
                    </div>
                    }
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Update Product</button>
                        <button className={["btn btn-danger float-right", isLoading ? "btn-progress" : ""].join(" ")} onClick={deleteHandler} disabled={isLoading}>Delete Current Product</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default UpdateProduct;