import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import millionaireStore from './store/millionaireStore';
import {BrowserRouter} from 'react-router-dom'
millionaireStore();
ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
  document.getElementById('app')
);
