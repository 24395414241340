import React, { useContext, useEffect, useState } from "react";
// import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import { NavLink, useNavigate  } from "react-router-dom";
import SweetAlert from "../../Components/SweetAlert";

const LuckyTokens = (props) =>
{
    const context = useContext(AuthContext);
    const [globalState, dispatch] = useStore();
    const [tableExport, setTableExport] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const {tokens, products} = globalState;
    const initialFields = {
      productId: products[0]?.id,
      rounde: 1,
      amount: 5,
    }
    const [fields, setFields] = useState({...initialFields});

    useEffect(() =>
    {
      if(tokens.length > 0)
      {
        let tables = window.loadTable();
        setTableExport(tables.tableExport);
      }
    }, [tokens]);
    useEffect(() =>
    {
      window.loadForm();
    }, []);


    const submitHandler = async (e) =>
    {
      if(isLoading)
        return;

      e.preventDefault();

      if(Object.hasOwnProperty.call(fields, "productId") && (fields.productId == null || fields.productId == undefined || fields.productId == "None"))
        return SweetAlert("warning", "Please Enter Product");

      if(Object.keys(fields).length <= 0)
        return

      try {
      setIsLoading(true);
      const {data, status, message} = await (await fetch(serverPath("/create_lucky"), {
        method: "PUT",
        headers: {
          "Content-Type": "Application/JSON",
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: JSON.stringify(fields)
      })).json();



      if(status === "failure")
         SweetAlert("warning", message)
         
      if(status === "success")
        if(data.length >= 1)
        {
          SweetAlert("success", "Generated " + data.length + " Lucky Tokens ");
        }else
        {
          SweetAlert("info", "No Lucky Token Generated")
        }


    } catch (error) {
        SweetAlert("warning", error.message)
    }
    setIsLoading(false);
    }

    const changeHandler = (value, type) =>
    {
      setFields(prev => ({...prev, [type]: value}));
    }


    return (
      <div className="main-content">
        <section className="section">
          <div className="section-body">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <form onSubmit={submitHandler}>
                  <div className="card-header">
                    <h4>Find By Fields</h4>
                  </div>
                  <div className="card-body row">
                    <div className="form-group col-lg-4 col-md-6 col-12">
                      <div className="row">
                        <div className="col-11">
                          <label>Products</label>
                          <select className="form-control" value={fields.productId} onChange={({target}) => changeHandler(target.value, "productId")}>
                            <option value={null}>None</option>
                            {products.map(product => (
                              <option value={product.id} key={product.id}>{product.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-4 col-md-6 col-12">
                      <div className="row">
                        <div className="col-11">
                          <label>Rounde</label>
                          <input type="number" className="form-control" value={fields.rounde} onChange={({target}) => changeHandler(target.value, "rounde")}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-lg-4 col-md-6 col-12">
                      <div className="row">
                        <div className="col-11">
                          <label>Amount</label>
                          <input type="number" className="form-control" value={fields.amount} onChange={({target}) => changeHandler(target.value, "amount")}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-right">
                    <button className={["btn col-md-3 btn-primary mr-1", isLoading ? "btn-progress" : ''].join(' ')} type="submit" disabled={isLoading}>Submit</button>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}


export default LuckyTokens;