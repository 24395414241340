import React, {useContext, useEffect, useRef, useState} from "react";
import styles from './style.module.css';
import useStore from "../../store/store";
import SweetAlert from "../../Components/SweetAlert";
import serverPath from "../../utils/serverPath";
import { AuthContext } from "../../authContext";
import { useNavigate } from "react-router-dom";
import DRAW from '../../Assets/draw.jpg';
import DRAW_BG from '../../Assets/drawbg.jpg';
const ShowDraw = (props) =>
{
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [globalState, dispatch] = useStore();
  const {products, tokens} = globalState;

  const [luckyTokens, setLuckyTokens] = useState([]);
  const initAnimateLucky  = {
    duration: 10,
    tokens: tokens,
    isStarted: false,
    spinning: false,
    selectedTokens: []
  }
  const [animateLucky, setAnimateLucky] = useState({...initAnimateLucky});
  const [isLoading, setIsLoading] = useState(false);
  const animationElm = useRef()
  const initialFilter = {
    productId: {
      checked: false,
      value: products[0]?.id,
    },
    isLucky: {
      checked: true,
      value: true,
    },
    rounde: {
      checked: false,
      value: 1,
    },
  }
  const [filter, setFilter] = useState({...initialFilter});

  const submitHandler = async (e) =>
  {
    if(isLoading)
      return;

    e.preventDefault();
    const queryFields = {};
    for (const key in filter) {
      if (Object.hasOwnProperty.call(filter, key)) {
        const element = filter[key];
        if(element.checked)
          queryFields[key] = element.value
      }
    }

    if(Object.hasOwnProperty.call(queryFields, "productId") && (queryFields.productId == null || queryFields.productId == undefined || queryFields.productId == "None"))
      return SweetAlert("warning", "Please Enter Product");

    if(Object.keys(queryFields).length <= 0)
      return

    try {
    setIsLoading(true);
    const {data, status} = await (await fetch(serverPath("/query"), {
      method: "POST",
      headers: {
        "Content-Type": "Application/JSON",
        "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
      },
      body: JSON.stringify(queryFields)
    })).json();

    if(status === "success")
      if(data.count >= 1)
      {
        setLuckyTokens(data.rows)
        SweetAlert("success", "Successfuly Loaded You Can Draw Now");
      }else
      {
        setLuckyTokens([])
        SweetAlert("info", "Nothing Found On this You Cant Draw Without Tokens")
      }
  } catch (error) {
      SweetAlert("warning", error.message)
  }
  setIsLoading(false);
  }
  
  const onCheck = (value, type) =>
  {
    setFilter(prev => ({...prev, [type]: {...prev[type], checked: value}}));
  }

  const changeHandler = (value, type) =>
  {
    setFilter(prev => ({...prev, [type]: {...prev[type], value}}));
  }
  const drawChangeHandler = (value, type) =>
  {
    setAnimateLucky(prev => ({...prev, [type]: value}));
  }

  const onDraw = (e) =>
  {
    e.preventDefault()
    setAnimateLucky(prev => ({...prev, isStarted: true, spinning: true, selectedTokens: []}));
    let initValues = {
      y: 100,
      i: 1,
      x: 100,
    };
    let values = {...initValues}

    values.x = setInterval(() => {
      let selectedToken = luckyTokens[(luckyTokens.length - 1) < (values.i - 1) ? (luckyTokens.length - 1) : (values.i - 1)];
      setAnimateLucky(prev => ({...prev, spinning: false, selectedTokens: [...prev.selectedTokens, selectedToken],  tokens: [selectedToken,...prev.tokens]}));
      if(luckyTokens.length === values.i)
        return clearInterval(values.x);
        
      values.y = setInterval(() => {
        values.i = (values.i + 1);
        setAnimateLucky(prev => ({...prev, spinning: true}));
        clearInterval(values.y);
      },(1000));

    },(animateLucky.duration * 1000))


    let z = setTimeout(() => {
      clearInterval(values.x);
      clearInterval(values.y);
      setAnimateLucky(prev => ({...initAnimateLucky, selectedTokens: prev.selectedTokens}));
      values = {...initValues};
      clearTimeout(z);
    }, ((animateLucky.duration * 1000) * luckyTokens.length) + 1000 )
  }

  useEffect(() => {
    if(!(context?.role?.pages === '*'))
      navigate('/', {replace: true})
  }, []);

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={submitHandler}>
                <div className="card-header">
                  <h4>Find By Filter</h4>
                </div>
                <div className="card-body row">
                    <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.productId.checked} onChange={({target}) => onCheck(target.checked, "productId")}/>
                      </div>
                      <div className="col-11">
                        <label>Products</label>
                        <select className="form-control" value={filter.productId.value} disabled={!(filter.productId.checked)} required={filter.productId.checked} onChange={({target}) => changeHandler(target.value, "productId")}>
                          <option value={null}>None</option>
                          {products.map(product => (
                            <option value={product.id} key={product.id}>{product.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.rounde.checked} onChange={({target}) => onCheck(target.checked, "rounde")}/>
                      </div>
                      <div className="col-11">
                        <label>Rounde</label>
                        <input type="number" className="form-control" value={filter.rounde.value} disabled={!(filter.rounde.checked)}  required={filter.rounde.checked} onChange={({target}) => changeHandler(target.value, "rounde")}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button className={["btn col-md-3 btn-primary mr-1", isLoading ? "btn-progress" : ''].join(' ')} type="submit" disabled={isLoading}>Submit</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {luckyTokens.length > 0 &&
      <section className="section" >
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Draw And Show Tokens</h4>
              </div>
              <form onSubmit={onDraw}>
                <div className="card-body row">
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-11">
                        <label>Duration By seconds</label>
                        <input type="number" className="form-control" min={2} max={100} value={animateLucky.duration} disabled={animateLucky.isStarted}  required onChange={({target}) => drawChangeHandler(target.value, "duration")}/>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-2 col-md-6 col-12">
                    <div className="row">
                      <div className="col-11">
                        <label> </label>
                        <button className={["btn btn-danger form-control mr-1", animateLucky.isStarted ? "btn-progress" : ''].join(' ')} type="submit" disabled={animateLucky.isStarted}>Draw</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="card-footer">
                <h4>Lucky Tokens</h4>
                <div className="row">
                  { animateLucky.selectedTokens.map(per => (
                    <div key={per.id} className={["col-lg-3 col-md-4 col-12 bg-danger", styles.luckyToken].join(' ')}>
                      {per.token}
                    </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className="card" style={{backgroundImage: `url(${DRAW_BG})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}}>
              <div className={[styles.text, 'm-auto'].join(" ")}>
                <div className={styles.drawImg}>
                  <img src={DRAW} style={{borderRadius: "50%"}} />
                  <div className={[styles.animatingTokens, styles.scalling].join(" ")} ref={animationElm}>
                    <div className={animateLucky.spinning ? styles.top : ""} style={{animationDuration: `${animateLucky.duration}s`}}>
                      {animateLucky.isStarted ? animateLucky.tokens.map((per, index) => (
                        <p key={index}>{per.token}</p>
                      )): <p>T.O.K.E.N</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
      </div>
  )
}

export default ShowDraw;