import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate, useParams} from 'react-router-dom';
const UpdateRole = (props) =>
{

  const {id} = useParams();

    const initRole = {
      name: "Co Admin",
      permissions: [
        {
          gift: {
              get: false,
              post: false,
              delete: false,
              update: false
          }
        }
      ],
    };
    const navigate = useNavigate();
    const [globalState, dispatch] = useStore(false);
    const {admins} = globalState;
    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = useState({...initRole});

    useEffect(() =>
    {

      const admn = admins.find(admn => admn.id == id)

      if(admins.length >= 1 && !(admn?.id))
        navigate("/admins", {replace: true})

      if(admn)     
      {
        setRole(prev => {
          let adminPermission = admn.role.permissions;
          let prevPermissions = prev.permissions;

          if(typeof(adminPermission) === "string")
            adminPermission = JSON.parse(adminPermission);

          if(typeof(prevPermissions) === "string")
            prevPermissions = JSON.parse(prevPermissions);

          let cloneUserPermission = [...adminPermission];
          prevPermissions.forEach(perPermission =>
            {
              const key = Object.keys(perPermission)[0];
              const findIndex = cloneUserPermission.findIndex(perUserPer => perUserPer[key]);
              if(!findIndex || findIndex < 0)
                cloneUserPermission.push(perPermission);
            })
          return {
            name: admn.role.name,
            permissions: cloneUserPermission,
          }
        })
      }

        window.loadFormImage();
        window.loadForm();
  
    }, [admins, id]);


    const changeHandler = (e, type) => {
        setRole(prev => ({...prev, [type]: e.target.value}));
    };
  
    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;

      setIsLoading(true);
      const response = await fetch(serverPath("/role"), {
        method: "PUT",
        headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`,
          "Content-Type": "Application/JSON"
        },
        body: JSON.stringify({...role, id: admins?.find(perAdmin => perAdmin.id == id)?.roleId})
      }); 
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const updateRole = objData.data;
        const adminsClone = [...admins];
        let adminIndex = adminsClone.findIndex(perAdmin => (perAdmin.id == id));
        if(adminIndex >= 0)
        {
          adminsClone[adminIndex].role = {...updateRole, ...role,};
          dispatch('setData', {type: 'admins', data: adminsClone});
        }
        return SweetAlert('success', "Role Successfully added!").then(res => navigate('/admins', {replace: true}));

    }

    const roleChangeHandler = (type, locatedIndex, value, checkedType) =>
    {
      setRole(prev => {
        let clonePermissions = prev.permissions;
        if(typeof(clonePermissions) === "string")
          clonePermissions = [...JSON.parse(clonePermissions)];

        let specificPermissionClone = clonePermissions[locatedIndex][type];
        specificPermissionClone[checkedType] = value;
        return {
          ...prev,
          permissions: clonePermissions
        }
      });
    }

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Update Role</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-left col-12">Role Name</label>
                      <div className="col-12 col-md-4">
                        <input type="text" className="form-control" name="name" value={role.name} onChange={(e) => changeHandler(e, "name")} required placeholder="Role Name"/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <div className="card-body">
                        <div className="section-title mt-0">Permissions</div>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Access To</th>
                              <th scope="col">GET</th>
                              <th scope="col">POST</th>
                              <th scope="col">UPDATE</th>
                              <th scope="col">DELETE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                            role.permissions.map((permission, index) => {
                              const key = Object.keys(permission)[0];
                              if(key === 'pages')
                                return null
                              return(
                              <tr key={index}>
                                <th scope="row">{key.toUpperCase()}</th>
                                <td>
                                  <input type="checkbox" value={permission[key].get} checked={permission[key].get} onChange={({target}) => roleChangeHandler(key, index, target.checked, "get")}/>
                                </td>
                                <td>
                                  <input type="checkbox" value={permission[key].post} checked={permission[key].post} onChange={({target}) => roleChangeHandler(key, index, target.checked, "post")}/>
                                </td>
                                <td>
                                  <input type="checkbox" value={permission[key].update} checked={permission[key].update} onChange={({target}) => roleChangeHandler(key, index, target.checked, "update")}/>
                                </td>
                                <td>
                                  <input type="checkbox" value={permission[key].delete} checked={permission[key].delete} onChange={({target}) => roleChangeHandler(key, index, target.checked, "delete")}/>
                                </td>
                              </tr>
                              )
                            })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-info float-right mr-1", isLoading ? "btn-progress" : ""].join(" ")} onClick={submitHandler} disabled={isLoading}>Edit Role</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default UpdateRole;