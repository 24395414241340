import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import createValidForm from '../../utils/createValidForm';
import SweetAlert from "../../Components/SweetAlert";
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import {useNavigate} from 'react-router-dom';
import { AuthContext } from "../../authContext";
const CreateSlider = (props) =>
{

    const initSlider = {
      imagePath: null,
    };
    const navigate = useNavigate();
    const [globalState, dispatch] = useStore();
    const {sliders} = globalState;
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState({...initSlider});

    useEffect(() =>
    {
      window.loadFormImage();
    }, []);


    const changeHandler = (e, type) => {
      if(type !== "imagePath")
        setFields(prev => ({...prev, [type]: e.target.value}));
  
      if(type === 'imagePath')
        if(e?.target?.files[0]?.name)
          setFields(prev => ({...prev, [type]: e.target.files[0]}))
    };
  




    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return null;

      if(!(fields?.imagePath?.name))
        return SweetAlert("error", "Slider Image Is Required!");
  
      const form = createValidForm({imagePath: fields.imagePath});
  
      setIsLoading(true);
      const response = await fetch(serverPath("/slider"), {
        method: "POST",
        headers: {
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: form
      });
  
      const objData = await response.json();
      
      setIsLoading(false)
      if(objData.status === "failure")
        return SweetAlert('error', objData.message);
        
        const addedSlid = objData.data;
        const newSlids = [...sliders, ...addedSlid];
        dispatch('setData', {type: 'sliders', data: newSlids});
        
        return SweetAlert('success', "Slider Successfully added!").then(res => navigate('/sliders', {replace: true}));

    }

    

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Create Your Slider</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Image</label>
                      <div className="col-sm-12 col-md-7">
                        <div id="image-preview" className="image-preview">
                          <label htmlFor="image-upload" id="image-label">Choose File</label>
                          <input type="file" name="imagePath" id="image-upload" accept="image/*" required  onChange={(e) => changeHandler(e, "imagePath")}/>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Create Slider</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default CreateSlider;