import React, {useContext, useEffect, useState} from "react";
import styles from './style.module.css';
import { useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import { AuthContext } from "../../authContext";
const Home = (props) =>
{
  const {role} = useContext(AuthContext);
  const [globalState] = useStore();
  const {admins, extras, tokensSales} = globalState;
  const [topAdmins, setTopAdmins] = useState([]);
  const navigate = useNavigate();
  useEffect(() =>
  {
    // navigate("/profile", {replace: true});
    setTopAdmins(admins.sort((a, b) => (b?.soldTokensCount - a?.soldTokensCount)).slice(0, 3))
    window.loadContent2();
  }, [admins])
  return(
    <div className="main-content">
    {
      (role?.pages === "*") ? 
      <section className="section">
        <div className="row ">
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="card">
              <div className="card-statistic-4">
                <div className="align-items-center justify-content-between">
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                      <div className="card-content">
                        <h5 className="font-15">Total Tokens</h5>
                        <h2 className="mb-3 font-18">{extras?.totalTokens}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                      <div className="banner-img">
                        <img src="assets/img/banner/1.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="card">
              <div className="card-statistic-4">
                <div className="align-items-center justify-content-between">
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                      <div className="card-content">
                        <h5 className="font-15">Unsold Tokens</h5>
                        <h2 className="mb-3 font-18">{extras?.totalUnsoldTokens}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                      <div className="banner-img">
                        <img src="assets/img/banner/3.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="card">
              <div className="card-statistic-4">
                <div className="align-items-center justify-content-between">
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                      <div className="card-content">
                        <h5 className="font-15">Sold Tokens</h5>
                        <h2 className="mb-3 font-18">{extras?.totalSoldTokens}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                      <div className="banner-img">
                        <img src="assets/img/banner/4.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="card">
              <div className="card-statistic-4">
                <div className="align-items-center justify-content-between">
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pr-0 pt-3">
                      <div className="card-content">
                        <h5 className="font-15">Total Users</h5>
                        <h2 className="mb-3 font-18">{extras?.totalUsers}</h2>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 pl-0">
                      <div className="banner-img">
                        <img src="assets/img/banner/2.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {/* <div className="row">
          <div className="col-12 col-sm-12 col-lg-12">
            <div className="card ">
              <div className="card-header">
                <h4>Revenue chart</h4>
                <div className="card-header-action">
                  <div className="dropdown">
                    <a href="#" data-toggle="dropdown" className="btn btn-warning dropdown-toggle">Options</a>
                    <div className="dropdown-menu">
                      <a href="#" className="dropdown-item has-icon"><i className="fas fa-eye"></i> View</a>
                      <a href="#" className="dropdown-item has-icon"><i className="far fa-edit"></i> Edit</a>
                      <div className="dropdown-divider"></div>
                      <a href="#" className="dropdown-item has-icon text-danger"><i className="far fa-trash-alt"></i>
                        Delete</a>
                    </div>
                  </div>
                  <a href="#" className="btn btn-primary">View All</a>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-9">
                    <div id="chart1"></div>
                    <div className="row mb-0">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="list-inline text-center">
                          <div className="list-inline-item p-r-30"><i data-feather="arrow-up-circle"
                              className="col-green"></i>
                            <h5 className="m-b-0">$675</h5>
                            <p className="text-muted font-14 m-b-0">Weekly Earnings</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="list-inline text-center">
                          <div className="list-inline-item p-r-30"><i data-feather="arrow-down-circle"
                              className="col-orange"></i>
                            <h5 className="m-b-0">$1,587</h5>
                            <p className="text-muted font-14 m-b-0">Monthly Earnings</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                        <div className="list-inline text-center">
                          <div className="list-inline-item p-r-30"><i data-feather="arrow-up-circle"
                              className="col-green"></i>
                            <h5 className="mb-0 m-b-0">$45,965</h5>
                            <p className="text-muted font-14 m-b-0">Yearly Earnings</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="row mt-5">
                      <div className="col-7 col-xl-7 mb-3">Total customers</div>
                      <div className="col-5 col-xl-5 mb-3">
                        <span className="text-big">8,257</span>
                        <sup className="col-green">+09%</sup>
                      </div>
                      <div className="col-7 col-xl-7 mb-3">Total Income</div>
                      <div className="col-5 col-xl-5 mb-3">
                        <span className="text-big">$9,857</span>
                        <sup className="text-danger">-18%</sup>
                      </div>
                      <div className="col-7 col-xl-7 mb-3">Project completed</div>
                      <div className="col-5 col-xl-5 mb-3">
                        <span className="text-big">28</span>
                        <sup className="col-green">+16%</sup>
                      </div>
                      <div className="col-7 col-xl-7 mb-3">Total expense</div>
                      <div className="col-5 col-xl-5 mb-3">
                        <span className="text-big">$6,287</span>
                        <sup className="col-green">+09%</sup>
                      </div>
                      <div className="col-7 col-xl-7 mb-3">New Customers</div>
                      <div className="col-5 col-xl-5 mb-3">
                        <span className="text-big">684</span>
                        <sup className="col-green">+22%</sup>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-12 col-sm-12 col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4>Chart</h4>
              </div>
              <div className="card-body">
                <div id="chart4" className="chartsh"></div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4>Chart</h4>
              </div>
              <div className="card-body">
                <div className="summary">
                  <div className="summary-chart active" data-tab-group="summary-tab" id="summary-chart">
                    <div id="chart3" className="chartsh"></div>
                  </div>
                  <div data-tab-group="summary-tab" id="summary-text">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4>Chart</h4>
              </div>
              <div className="card-body">
                <div id="chart2" className="chartsh"></div>
              </div>
            </div>
          </div>
        </div> */}
        <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>My Sales</h4>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                    <tr className="text-center">
                      <th >#</th>
                      <th>Product</th>
                      <th>Total Sold</th>
                      <th>Total Unsold</th>
                    </tr>
                    {
                      tokensSales.map((sale, index) => (
                      <tr key={sale.id} className="text-center">
                        <td >{(index + 1)}</td>
                        <td>{sale.name}</td>
                        <td>{sale.soldTokensCount}</td>
                        <td>{sale.unSoldTokensCount}</td>
                      </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>Top Admins</h4>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                    <tr className="text-center">
                      <th>Admin</th>
                      <th>Total Sold</th>
                      <th>Total Unsold</th>
                      <th>Email</th>
                    </tr>
                    {
                      topAdmins.map(perAdmin => (
                      <tr key={perAdmin.id} className="text-center">
                        <td>{perAdmin.firstName + " " + perAdmin.lastName}</td>
                        <td>{perAdmin.soldTokensCount}</td>
                        <td>{perAdmin.unsoldTokensCount}</td>
                        <td>{perAdmin.email}</td>
                      </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-6 col-lg-12 col-xl-6">
            <div className="card">
              <div className="card-header">
                <h4>Support Ticket</h4>
                <form className="card-header-form">
                  <input type="text" name="search" className="form-control" placeholder="Search"/>
                </form>
              </div>
              <div className="card-body">
                <div className="support-ticket media pb-1 mb-3">
                  <img src="assets/img/users/user-1.png" className="user-img mr-2" alt="" />
                  <div className="media-body ml-3">
                    <div className="badge badge-pill badge-success mb-1 float-right">Feature</div>
                    <span className="font-weight-bold">#89754</span>
                    <a href="#">Please add advance table</a>
                    <p className="my-1">Hi, can you please add new table for advan...</p>
                    <small className="text-muted">Created by <span className="font-weight-bold font-13">John
                        Deo</span>
                      &nbsp;&nbsp; - 1 day ago</small>
                  </div>
                </div>
                <div className="support-ticket media pb-1 mb-3">
                  <img src="assets/img/users/user-2.png" className="user-img mr-2" alt="" />
                  <div className="media-body ml-3">
                    <div className="badge badge-pill badge-warning mb-1 float-right">Bug</div>
                    <span className="font-weight-bold">#57854</span>
                    <a href="#">Select item not working</a>
                    <p className="my-1">please check select item in advance form not work...</p>
                    <small className="text-muted">Created by <span className="font-weight-bold font-13">Sarah
                        Smith</span>
                      &nbsp;&nbsp; - 2 day ago</small>
                  </div>
                </div>
                <div className="support-ticket media pb-1 mb-3">
                  <img src="assets/img/users/user-3.png" className="user-img mr-2" alt="" />
                  <div className="media-body ml-3">
                    <div className="badge badge-pill badge-primary mb-1 float-right">Query</div>
                    <span className="font-weight-bold">#85784</span>
                    <a href="#">Are you provide template in Angular?</a>
                    <p className="my-1">can you provide template in latest angular 8.</p>
                    <small className="text-muted">Created by <span className="font-weight-bold font-13">Ashton Cox</span>
                      &nbsp;&nbsp; -2 day ago</small>
                  </div>
                </div>
                <div className="support-ticket media pb-1 mb-3">
                  <img src="assets/img/users/user-6.png" className="user-img mr-2" alt="" />
                  <div className="media-body ml-3">
                    <div className="badge badge-pill badge-info mb-1 float-right">Enhancement</div>
                    <span className="font-weight-bold">#25874</span>
                    <a href="#">About template page load speed</a>
                    <p className="my-1">Hi, John, can you work on increase page speed of template...</p>
                    <small className="text-muted">Created by <span className="font-weight-bold font-13">Hasan
                        Basri</span>
                      &nbsp;&nbsp; -3 day ago</small>
                  </div>
                </div>
              </div>
              <a href="#" className="card-footer card-link text-center small ">View
                All</a>
            </div>
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6">
            <div className="card">
              <div className="card-header">
                <h4>Projects Payments</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover mb-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Client Name</th>
                        <th>Date</th>
                        <th>Payment Method</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>John Doe </td>
                        <td>11-08-2018</td>
                        <td>NEFT</td>
                        <td>$258</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Cara Stevens
                        </td>
                        <td>15-07-2018</td>
                        <td>PayPal</td>
                        <td>$125</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          Airi Satou
                        </td>
                        <td>25-08-2018</td>
                        <td>RTGS</td>
                        <td>$287</td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          Angelica Ramos
                        </td>
                        <td>01-05-2018</td>
                        <td>CASH</td>
                        <td>$170</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>
                          Ashton Cox
                        </td>
                        <td>18-04-2018</td>
                        <td>NEFT</td>
                        <td>$970</td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>
                          John Deo
                        </td>
                        <td>22-11-2018</td>
                        <td>PayPal</td>
                        <td>$854</td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>
                          Hasan Basri
                        </td>
                        <td>07-09-2018</td>
                        <td>Cash</td>
                        <td>$128</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </section>
      :
      <section className="section">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h4>My Sales</h4>
              </div>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                    <tr className="text-center">
                      <th >#</th>
                      <th>Product</th>
                      <th>Total Sold</th>
                      <th>Total Unsold</th>
                    </tr>
                    {
                      tokensSales.map((sale, index) => (
                      <tr key={sale.id} className="text-center">
                        <td >{(index + 1)}</td>
                        <td>{sale.name}</td>
                        <td>{sale.soldTokensCount}</td>
                        <td>{sale.unSoldTokensCount}</td>
                      </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    }
    </div>
  )
}



export default Home;