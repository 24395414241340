import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import validator from '../../validators/currency'
import useStore from "../../store/store";
import SweetAlert from "../../Components/SweetAlert";
import serverPath from "../../utils/serverPath";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import AskAlert from "../../utils/AskAlert";
import deleteOne from "../../utils/deleteOne";

const UpdateCurrency = (props) =>
{
  const {id} = useParams();
    
    const navigate = useNavigate()
    const [globalState, dispatch] = useStore();
    const {currencies} = globalState;
    const [isLoading, setIsLoading] = useState(false);

  
  let defaultValue = {
    mainName: "",
    amount: 0,
    vector: false,
    status: true,
    subCurrencies: []
  }
  let defaultSub = {
    name: "",
    amount: 0,
    vector: false
  }

  const [fields, setFields] = useState({...defaultValue});
  const changeHandler = (e, type) => setFields(prev => ({...prev, [type]: e.target.value}));
  const changeSubHandler = (e, index, type) => setFields(prev => {
    const cloneArray = [...prev.subCurrencies];
    cloneArray[index][type] = e.target.value;
    return {...prev, subCurrencies: cloneArray}
  });
    useEffect(() =>
    {
      (async() =>{

        const find = currencies.find(perField => perField.id == id)

        if(!(find?.id))
          navigate("/products", {replace: true})   

        if(find?.id)
        { 
          setFields(prev => {
            let subCurrencies = find.subCurrencies;
            if(typeof(subCurrencies) === "string")
              {
                subCurrencies = JSON.parse(subCurrencies);

                if(typeof(subCurrencies) === "string")
                  subCurrencies = JSON.parse(subCurrencies);
              }
            return {
              mainName: find.mainName,
              amount: find.amount,
              vector: find.vector,
              status: find.status,
              subCurrencies: subCurrencies
          }
        });
        }
  
        window.loadForm();
      })()
    }, [id])


    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;
      
      try {
        const {status, data, message} = validator(fields);
        if(status === 'failure')
          return SweetAlert("info", message);
        setIsLoading(true);
        const {data : objData} = await axios.put(
          serverPath("/currency"), 
          {...data, id},
          {headers: {
            "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
          }}
        )
          
        setIsLoading(false);
        if(objData.status === "failure")
          return SweetAlert('error', objData.message);
        
        const updateCurrency = objData.data;
        const clone = [...currencies];
        let index  = clone.findIndex(per => per.id == id)
        if(index >= 0)
        {
          clone[index] = { ...updateCurrency};
          dispatch('setData', {type: 'currencies', data: clone});
        }
        
        return SweetAlert('success',"Currency Successfully updated!").then(res => navigate('/currencies', {replace: true}));
  
  
      } catch (error) {
        return SweetAlert("error", error.message);
      }
    }


    const addSubHandler = (e) =>
    {
      e.preventDefault();
      setFields(prev => {
        const cloneSubs = [...prev.subCurrencies];
        cloneSubs.push({...defaultSub});
        return {...prev, subCurrencies: cloneSubs}
      })
    }

    const removeSubHandler = (e, index) =>
    {
      e.preventDefault();
      setFields(prev => {
        const cloneSubs = [...prev.subCurrencies];
        cloneSubs.splice(index, 1);
        return {...prev, subCurrencies: cloneSubs}
      })
    }

    const deleteHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;
        
      let sure = await AskAlert({text: "Sure to delete this currency!"})
      if(!sure)
        return;
      setIsLoading(true);  
      const {status, message} = await deleteOne('currency', id);
      setIsLoading(false);
      if(status === 'success')
      {
        const newCurr = [...currencies];
        let index = newCurr.findIndex(per => per.id == id);
        if(index >= 0)
        {
          newCurr.splice(index, 1);
          dispatch('setData', {type: 'currencies', data: newCurr});
          SweetAlert('success', "Successfully Deleted");
        }
        navigate('/currencies', {replace: true});
      }
      if(status === 'failure')
      {
        SweetAlert("error", message);
      }
    }

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Update Currency</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Main Currency</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="text" className="form-control"placeholder="Currency Name" required value={fields.mainName} onChange={(e) => changeHandler(e, "mainName")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Amount</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="number" className="form-control"placeholder="Must Be Numeric Value" required value={fields.amount} onChange={(e) => changeHandler(e, "amount")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Vector</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required value={fields.vector} onChange={(e) => changeHandler(e, "vector")} >
                          <option value={false}>Down</option>
                          <option value={true}>Up</option>
                        </select>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Vector</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required value={fields.status} onChange={(e) => changeHandler(e, "status")} >
                          <option value={true}>Active</option>
                          <option value={false}>Suspend</option>
                        </select>
                      </div>
                    </div>
                    {fields.subCurrencies.map((perCurrency, index) => (
                    <div key={index}>
                      <div className="form-group row mb-4 p-t-20" style={{borderTop: "1px solid rgba(0, 0, 0, 0.1)"}}>
                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Currency Name</label>
                        <div className="col-sm-12 col-md-7">
                          <input type="text" className="form-control"placeholder="Currency Name" required value={perCurrency.name} onChange={(e) => changeSubHandler(e, index, "name")}/>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Amount</label>
                        <div className="col-sm-12 col-md-7">
                          <input type="number" className="form-control"placeholder="Must Be Numeric Value" required value={perCurrency.amount} onChange={(e) => changeSubHandler(e, index, "amount")}/>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Vector</label>
                        <div className="col-sm-12 col-md-7">
                          <select className="form-control" required value={perCurrency.vector} onChange={(e) => changeSubHandler(e, index, "vector")} >
                            <option value={false}>Down</option>
                            <option value={true}>Up</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row mb-4">
                        <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                        <div className="col-sm-6 col-md-3">
                          <button className={[`btn btn-danger ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading} onClick={(e) => removeSubHandler(e, index)}>Remove Sub</button>
                        </div>
                      </div>
                    </div>
                    ))}
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={["btn btn-primary", isLoading ? "btn-progress" : ""].join(" ")} disabled={isLoading}>Save Currency</button>
                        <button className={["btn btn-danger float-right", isLoading ? "btn-progress" : ""].join(" ")} onClick={deleteHandler} disabled={isLoading}>Delete Currency</button>
                        <button className={["btn btn-info float-right mr-1", isLoading ? "btn-progress" : ""].join(" ")} onClick={addSubHandler} disabled={isLoading}>Add Sub Currency</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default UpdateCurrency;