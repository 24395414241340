import * as yup from 'yup';
export default (data) =>
{
  let schema = yup.object().shape({
    mainName: yup.string().required(),
    amount: yup.number().required(),
    vector: yup.boolean().required(),
    subCurrencies: yup.array().of(
      yup.object().shape({
        name: yup.string().required(),
        amount: yup.number().required(),
        vector: yup.boolean().required(),
      })
    ),
  });

    try {
      schema.validateSync({...data});
      return {status: 'success', data: {...data}};
    } catch (error) {
      return {status: 'failure', message: error.errors[0]}
    }
}


// schema.validateSync({...data, categoryId: data?.categoryId?.id});
// return {status: 'success', data: {...data, categoryId: data?.categoryId?.id}};