import React, { useContext, useEffect, useState } from "react";
import styles from './style.module.css';
import { AuthContext } from "../../authContext";
import useStore from "../../store/store";
import SweetAlert from "../../Components/SweetAlert";
import serverPath from "../../utils/serverPath";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateToken = (props) =>
{
    const context = useContext(AuthContext);
    
    const navigate = useNavigate()
    const [globalState, dispatch] = useStore();
    const {products, admins} = globalState;
    const [isLoading, setIsLoading] = useState(false);

    
  let defaultValue = {
    count: 1,
    rounde: 1,
    productId: 0,
    adminId: context.user.id,
  }

  const [fields, setFields] = useState({...defaultValue});
  const changeHandler = (e, type) => setFields(prev => ({...prev, [type]: e.target.value}));
    useEffect(() =>
    {
      window.loadForm();
    }, [])


    const submitHandler = async (e) =>
    {
      e.preventDefault();
      if(isLoading)
        return;

      if(fields.count <= 0 || fields.rounde <= 0 || fields.productId <= 0)
        return SweetAlert("error", "Generate Input, Rounde Input, And Product Are Requred");

      
      try {
    
        setIsLoading(true);
        const {data : objData} = await axios.post(
          serverPath("/token"), 
          {...fields},
          {headers: {
            "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
          }}
        )
          
        setIsLoading(false);
        if(objData.status === "failure")
          return SweetAlert('error', objData.message);
        
        const addedToken = objData.data;
        // dispatch('setData', {type: 'tokens', data: newToken});
        
        return SweetAlert('success', addedToken?.length + " Tokens Successfully added!").then(res => navigate('/tokens', {replace: true}));
  
  
      } catch (error) {
        return SweetAlert("error", error.message);
      }
    }

    return (
      <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Create Your Token</h4>
                </div>
                <form onSubmit={submitHandler}>
                  <div className="card-body">
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Generate Tokens</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="number" className="form-control"placeholder="Must Be Numeric Value" required value={fields.count} onChange={(e) => changeHandler(e, "count")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Rounde Of Token</label>
                      <div className="col-sm-12 col-md-7">
                        <input type="number" className="form-control"placeholder="Must Be Numeric Value" required value={fields.rounde} onChange={(e) => changeHandler(e, "rounde")}/>
                      </div>
                    </div>
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Product</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required onChange={(e) => changeHandler(e, "productId")} value={fields.productId}>
                          <option value={0}>Select</option>
                          {products.map((product, index) => (
                            <option value={product.id} key={product.id}>{product.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    { (context.role.pages === "*") &&
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3">Admin</label>
                      <div className="col-sm-12 col-md-7">
                        <select className="form-control" required onChange={(e) => changeHandler(e, "adminId")} value={fields.adminId}>
                          <option value={0}>Select</option>
                          {admins.map((admin, index) => (
                            <option value={admin.id} key={admin.id}>{admin.firstName + " " + admin.lastName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    }
                    <div className="form-group row mb-4">
                      <label className="col-form-label text-md-right col-12 col-md-3 col-lg-3"></label>
                      <div className="col-sm-12 col-md-7">
                        <button className={[`btn btn-primary ${isLoading ? "btn-progress" : ""}`].join(' ')} disabled={isLoading}>Create Tokens</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
}


export default CreateToken;