import React, { useContext, useEffect, useState } from "react";
// import styles from './style.module.css';
import useStore from "../../store/store";
import serverPath from "../../utils/serverPath";
import { NavLink } from "react-router-dom";
import SweetAlert from "../../Components/SweetAlert";
import { AuthContext } from "../../authContext";
const Tokens = (props) =>
{
  const [globalState, dispatch] = useStore();
  const context = useContext(AuthContext);
  const [tableExport, setTableExport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [oldSearch, setOldSearch] = useState({});
  const {tokens, products, admins, gifts} = globalState;

  const initialFilter = {
    productId: {
      checked: false,
      value: products[0]?.id,
    },
    sold: {
      checked: false,
      value: 0,
    },
    rounde: {
      checked: false,
      value: 1,
    },
    isLucky: {
      checked: false,
      value: 0,
    },
    token: {
      checked: false,
      value: 0,
    },
    adminId: {
      checked: false,
      value: 0,
    },
    giftId: {
      checked: false,
      value: 0,
    },
  }
  const [filter, setFilter] = useState({...initialFilter});

  useEffect(() =>
  {
    if(tokens.length > 0)
    {
      let tables = window.loadTable();
      setTableExport(tables.tableExport);
    }
  }, [tokens]);
  useEffect(() =>
  {
    window.loadForm();
  }, []);


  const submitHandler = async (e) =>
  {
    if(isLoading)
      return;

    e.preventDefault();
    const queryFields = {};
    for (const key in filter) {
      if (Object.hasOwnProperty.call(filter, key)) {
        const element = filter[key];
        if(element.checked)
          queryFields[key] = element.value
      }
    }

    if(Object.hasOwnProperty.call(queryFields, "productId") && (queryFields.productId == null || queryFields.productId == undefined || queryFields.productId == "None"))
      return SweetAlert("warning", "Please Enter Product");
    if(Object.hasOwnProperty.call(queryFields, "adminId") && (queryFields.adminId == null || queryFields.adminId == undefined || queryFields.adminId == "None"))
      return SweetAlert("warning", "Please Enter Admin");

    if(Object.keys(queryFields).length <= 0)
      return

    try {
    setIsLoading(true);
    const {data, status} = await (await fetch(serverPath("/query"), {
      method: "POST",
      headers: {
        "Content-Type": "Application/JSON",
        "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
      },
      body: JSON.stringify(queryFields)
    })).json();

    if(status === "success")
      if(data.count >= 1)
      {
        tableExport?.destroy();
        dispatch('setData', {type: "tokens", data: data.rows});
        setOldSearch({...queryFields, pageNumber: 1});
        setCount(data.count);
        setPageNumber(1);
        context.setAuth(prev => ({...prev, tokenPageNumber: 1}));
        SweetAlert("success", "Filter Successfuly Loaded");
      }else
      {
        SweetAlert("info", "Nothing Found On this Filter")
      }


  } catch (error) {
      SweetAlert("warning", error.message)
  }
  setIsLoading(false);

  }

  const paginateHandler = async () =>
  {
    if(isLoading)
      return
    if((oldSearch.pageNumber == pageNumber))
      return SweetAlert("info", "You Already Have this filter page data")

    if((Math.ceil(count / 500)) < pageNumber)
      return SweetAlert("info", "You Entered Wrong Page Number")


    try {
      setIsLoading(true);
      const {data, status} = await (await fetch(serverPath("/query"), {
        method: "POST",
        headers: {
          "Content-Type": "Application/JSON",
          "Authorization": `bearer ${localStorage.getItem("A_TOKEN")}`
        },
        body: JSON.stringify({...oldSearch, pageNumber})
      })).json();

      if(status === "success")
        if(data.count >= 1)
        {
          setOldSearch(prev => ({...prev, pageNumber}));
          tableExport?.destroy();
          context.setAuth(prev => ({...prev, tokenPageNumber: pageNumber}));
          dispatch('setData', {type: "tokens", data: data.rows});
        }else
        {
          SweetAlert("info", "Nothing Found On this Filter");
        }


    } catch (error) {
        SweetAlert("warning", error.message)
    }
    setIsLoading(false);

  }

  const onCheck = (value, type) =>
  {
    setFilter(prev => ({...prev, [type]: {...prev[type], checked: value}}));
  }

  const changeHandler = (value, type) =>
  {
    setFilter(prev => ({...prev, [type]: {...prev[type], value}}));
  }

  return (
    <div className="main-content">
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <form onSubmit={submitHandler}>
                <div className="card-header">
                  <h4>Find By Filter</h4>
                </div>
                <div className="card-body row">
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.token.checked}  onChange={({target}) => onCheck(target.checked, "token")}/>
                      </div>
                      <div className="col-11">
                        <label>Token Number</label>
                        <input type="text" className="form-control" min={8} max={8} minLength={8} maxLength={8} value={filter.token.value} disabled={!(filter.token.checked)}  required={filter.token.checked} onChange={({target}) => changeHandler(target.value, "token")}/>
                      </div>
                    </div>
                  </div>
                  { (context?.role?.pages === '*' || context?.role?.product?.get) &&
                    <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.productId.checked} onChange={({target}) => onCheck(target.checked, "productId")}/>
                      </div>
                      <div className="col-11">
                        <label>Products</label>
                        <select className="form-control" value={filter.productId.value} disabled={!(filter.productId.checked)} required={filter.productId.checked} onChange={({target}) => changeHandler(target.value, "productId")}>
                          <option value={null}>None</option>
                          {products.map(product => (
                            <option value={product.id} key={product.id}>{product.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  }
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.sold.checked} onChange={({target}) => onCheck(target.checked, "sold")}/>
                      </div>
                      <div className="col-11">
                        <label>Sold</label>
                        <select className="form-control" value={filter.sold.value} disabled={!(filter.sold.checked)} required={filter.sold.checked} onChange={({target}) => changeHandler(target.value, "sold")}>
                          <option value={1}>True</option>
                          <option value={0}>False</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.rounde.checked} onChange={({target}) => onCheck(target.checked, "rounde")}/>
                      </div>
                      <div className="col-11">
                        <label>Rounde</label>
                        <input type="number" className="form-control" value={filter.rounde.value} disabled={!(filter.rounde.checked)}  required={filter.rounde.checked} onChange={({target}) => changeHandler(target.value, "rounde")}/>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.isLucky.checked} onChange={({target}) => onCheck(target.checked, "isLucky")}/>
                      </div>
                      <div className="col-11">
                        <label>Is Lucky</label>
                        <select className="form-control" value={filter.isLucky.value} disabled={!(filter.isLucky.checked)} required={filter.isLucky.checked} onChange={({target}) => changeHandler(target.value, "isLucky")}>
                          <option value={1}>True</option>
                          <option value={0}>False</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  { context?.role?.pages === '*' &&
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.adminId.checked} onChange={({target}) => onCheck(target.checked, "adminId")}/>
                      </div>
                      <div className="col-11">
                        <label>Admins</label>
                        <select className="form-control" value={filter.adminId.value} disabled={!(filter.adminId.checked)} required={filter.adminId.checked} onChange={({target}) => changeHandler(target.value, "adminId")}>
                          <option value={null}>None</option>
                          {admins.map(admin => (
                            <option value={admin.id} key={admin.id}>{admin.firstName + " " + admin.lastName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  }
                  { (context?.role?.pages === '*' || context?.role?.gift?.get) &&
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row">
                      <div className="col-1">
                        <input type="checkbox" className="mt-2"  value={filter.giftId.checked} onChange={({target}) => onCheck(target.checked, "giftId")}/>
                      </div>
                      <div className="col-11">
                        <label>Gifts</label>
                        <select className="form-control" value={filter.giftId.value} disabled={!(filter.giftId.checked)} required={filter.giftId.checked} onChange={({target}) => changeHandler(target.value, "giftId")}>
                          <option value={"null"}>Select</option>
                          {gifts.map(perField => (
                            <option value={perField.id} key={perField.id}>{perField.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  }
                </div>
                <div className="card-footer text-right">
                  <button className={["btn col-md-3 btn-primary mr-1", isLoading ? "btn-progress" : ''].join(' ')} type="submit" disabled={isLoading}>Submit</button>
                </div>
                </form>
                {
                  count >= 1 &&
                  <>
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <div className="row" style={{alignItems: "center"}}>
                      <div className="form-group col-lg-4 col-md-4 col-12">
                        Max Page Number <strong>{(Math.ceil(count / 500) || 1)}</strong>
                      </div>
                      <div className="form-group col-lg-4 col-md-4 col-12">
                        <input type="number" className="form-control" value={pageNumber} min={1} max={(Math.ceil(count / 500) || 1)} onChange={({target}) => {
                          setPageNumber(()=> target.value.replace(/[\D]/ig, ""))
                        }}/>
                      </div>
                      <div className="form-group col-lg-4 col-md-4 col-12">
                        <button className={["btn btn-primary mr-1", isLoading ? "btn-progress" : ''].join(' ')} type="submit" disabled={isLoading} onClick={paginateHandler}>New Page</button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-lg-4 col-md-6 col-12">
                    <h5>
                      All tokens of this filter is <strong>{count}</strong>
                    </h5>
                  </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="section-body">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Tokens Table</h4>
                  { context.role.pages === "*" &&
                    <NavLink to={'/tokens/print'} className="btn btn-primary">Print Cards</NavLink>
                  }
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover" id="tableExport" style={{width:"100%"}}>
                      <thead>
                        <tr>
                          <th className="text-center">
                            #
                          </th>
                          <th>Token Number</th>
                          <th>Rounde</th>
                          <th>Sold</th>
                          <th>Is Lucky</th>
                          <th>Gifted</th>
                          <th>Gift Taken</th>
                          <th>Product</th>
                          <th>Admin</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      {tokens.map((token, index) => (
                        <tr key={(token.id + index)}>
                          <td>
                            {(index + 1)}
                          </td>
                          <td>{token.token}</td>
                          <td className="align-middle">
                            {token.rounde}
                          </td>
                          <td>
                            {token.sold ?
                            <div className="badge badge-success badge-shadow">True</div>
                            :
                            <div className="badge badge-warning badge-shadow">False</div>
                            }
                          </td>
                          <td>
                            {token.isLucky ?
                            <div className="badge badge-success badge-shadow">True</div>
                            :
                            <div className="badge badge-warning badge-shadow">False</div>
                            }
                          </td>
                          <td>
                            {token.gifted ?
                            <div className="badge badge-success badge-shadow">True</div>
                            :
                            <div className="badge badge-warning badge-shadow">False</div>
                            }
                          </td>
                          <td>
                            {token.giftTaken ?
                            <div className="badge badge-success badge-shadow">True</div>
                            :
                            <div className="badge badge-warning badge-shadow">False</div>
                            }
                          </td>
                          <td>{token.product.name}</td>
                          <td>
                            <small style={{marginLeft: 5}}>
                              {token.admin.firstName + " " + token.admin.lastName}
                            </small>
                          </td>
                          <td>{(new Date(token.createdAt).toLocaleDateString())?.replace(/\//ig, "-")}</td>
                          <td>
                            {
                              !token.isLucky ?
                              <NavLink to={`/token/edit/${token.id}`} className="btn btn-primary">Edit / Delete</NavLink>
                              :
                              <p style={{fontSize: 10}}>Cant Update Lucky Token</p>
                            }
                          </td>
                        </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}


export default Tokens;